// Brand
export const GET_BRAND_INFO = 'GET_BRAND_INFO';
export const GET_BRAND_INFO_ERROR = 'GET_BRAND_INFO_ERROR';

// Session
export const SET_SESSION = 'SET_SESSION';

// Mailing Plan
export const GET_MAILING_PLAN = 'GET_MAILING_PLAN';
export const GET_MAILING_PLAN_ERROR = 'GET_MAILING_PLAN_ERROR';
export const LOADING_MAILING_PLAN = 'LOADING_MAILING_PLAN';
export const ADD_COMMENTS = 'ADD_COMMENTS';
export const GET_ZIP_CODES = 'GET_ZIP_CODES';
export const GET_ZIP_CODES_ERROR = 'GET_ZIP_CODES_ERROR';
export const GET_ZIP_CODES_LOADING = 'GET_ZIP_CODES_LOADING';
export const TOGGLE_ZIP_CODE = 'TOGGLE_ZIP_CODE';
export const TOGGLE_ALL_ZIP_CODES = 'TOGGLE_ALL_ZIP_CODES';
export const SUBMITTED_MAILING_PLAN = 'SUBMITTED_MAILING_PLAN';
export const SUBMITING_MAILING_PLAN = 'SUBMITING_MAILING_PLAN';
export const SUBMIT_MAILING_PLAN_ERROR = 'SUBMIT_MAILING_PLAN_ERROR';
export const SAVED_MAILING_PLAN = 'SAVED_MAILING_PLAN';
export const SAVING_MAILING_PLAN = 'SAVING_MAILING_PLAN';
export const CONFIRM_FRANCHISE_INFO = 'CONFIRM_FRANCHISE_INFO';
export const OVERRIDE_MAILING_PLAN_ENABLED = 'OVERRIDE_MAILING_PLAN_ENABLED';
export const OVERRIDE_MAILING_PLAN_LOADING = 'OVERRIDE_MAILING_PLAN_LOADING';
export const OVERRIDE_MAILING_PLAN_SHOW_CONFIRMATION =
    'OVERRIDE_MAILING_PLAN_SHOW_CONFIRMATION';
export const OVERRIDE_MAILING_PLAN_ERROR = 'OVERRIDE_MAILING_PLAN_ERROR';
export const RESET_MAILING_PLAN = 'RESET_MAILING_PLAN';
export const APPROVED_MAILING_PLAN = 'APPROVED_MAILING_PLAN';
export const APPROVING_MAILING_PLAN = 'APPROVING_MAILING_PLAN';
export const APPROVE_MAILING_PLAN_ERROR = 'APPROVE_MAILING_PLAN_ERROR';
export const REJECTED_MAILING_PLAN = 'REJECTED_MAILING_PLAN';
export const REJECTING_MAILING_PLAN = 'REJECTING_MAILING_PLAN';
export const REJECT_MAILING_PLAN_ERROR = 'REJECT_MAILING_PLAN_ERROR';
export const SET_MAILING_PLAN_STATUS = 'SET_MAILING_PLAN_STATUS';
export const INSUFFICIENT_ADDRESSES_WARNING = 'INSUFFICIENT_ADDRESSES_WARNING';
export const CHANGEFRANCHISELICENSE = 'CHANGEFRANCHISELICENSE';
export const CHANGEOWNERDETAILS = 'CHANGEOWNERDETAILS';

// Mailing Plan Submissions
export const GET_MAILING_PLAN_SUBMISSIONS = 'GET_MAILING_PLAN_SUBMISSIONS';
export const GET_MAILING_PLAN_SUBMISSIONS_LOADING =
    'GET_MAILING_PLAN_SUBMISSIONS_LOADING';
export const GET_MAILING_PLAN_SUBMISSIONS_ERROR =
    'GET_MAILING_PLAN_SUBMISSIONS_ERROR';
export const TOGGLE_MAILING_PLAN_SUBMISSIONS_MODAL =
    'TOGGLE_MAILING_PLAN_SUBMISSIONS_MODAL';

// Dashboard
export const GET_MAILING_PLANS = 'GET_MAILING_PLANS';
export const GET_MAILING_PLANS_LOADING = 'GET_MAILING_PLANS_LOADING';
export const GET_MAILING_PLANS_ERROR = 'GET_MAILING_PLANS_ERROR';
export const GET_MAILING_PLAN_STATUSES = 'GET_MAILING_PLAN_STATUSES';
export const GET_MAILING_PLAN_STATUSES_ERROR =
    'GET_MAILING_PLAN_STATUSES_ERROR';
export const GET_FRANCHISES_FILTER_DATA = 'GET_FRANCHISES_FILTER_DATA';
export const GET_FRANCHISES_DROPDOWN_FILTER_DATA = 'GET_FRANCHISES_DROPDOWN_FILTER_DATA';
export const GET_FRANCHISES_FILTER_DATA_ERROR =
    'GET_FRANCHISES_FILTER_DATA_ERROR';
export const GET_FRANCHISES_LOADING = 'GET_FRANCHISES_LOADING';
export const SAVING_FRANCHISE = 'SAVING_FRANCHISE';

export const GET_MAILING_YEARS = 'GET_MAILING_YEARS';
export const GET_MAILING_YEARS_ERROR = 'GET_MAILING_YEARS_ERROR';
export const GET_MAILING_PLANS_OVERVIEW = 'GET_MAILING_PLANS_OVERVIEW';
export const GET_MAILING_PLANS_OVERVIEW_ERROR =
    'GET_MAILING_PLANS_OVERVIEW_ERROR';
export const GET_CAMPAIGNS_TEMPLATES = 'GET_CAMPAIGNS_TEMPLATES';
export const GET_CAMPAIGNS_TEMPLATES_LOADING =
    'GET_CAMPAIGNS_TEMPLATES_LOADING';
export const GET_CAMPAIGNS_TEMPLATES_ERROR = 'GET_CAMPAIGNS_TEMPLATES_ERROR';
export const SET_MAILING_PLANS_START_DATE = 'SET_MAILING_PLANS_START_DATE';
export const SET_MAILING_PLANS_END_DATE = 'SET_MAILING_PLANS_END_DATE';
export const SET_MAILING_PLANS_STATUS_FILTER =
    'SET_MAILING_PLANS_STATUS_FILTER';
export const SET_MAILING_PLANS_FRANCHISE_FILTER =
    'SET_MAILING_PLANS_FRANCHISE_FILTER';
export const SET_MAILING_PLANS_YEAR_FILTER = 'SET_MAILING_PLANS_YEAR_FILTER';
export const SET_MAILING_PLANS_CAMPAIGN_DATE_FILTER =
    'SET_MAILING_PLANS_CAMPAIGN_DATE_FILTER';
export const SET_MAILING_PLANS_BROCHURE_FILTER =
    'SET_MAILING_PLANS_BROCHURE_FILTER';
export const SET_MAILING_PLANS_COUPON_FILTER =
    'SET_MAILING_PLANS_COUPON_FILTER';
export const FRANCONNECT_SYNC_ERROR = 'FRANCONNECT_SYNC_ERROR';
export const SYNCHING_FRANCONNECT = 'SYNCHING_FRANCONNECT';
export const FRANCONNECT_SYNC_SUCCESS = 'FRANCONNECT_SYNC_SUCCESS';
export const SET_MAILING_PLANS_DM_EXEMPT_STATUS_FILTER =
    'SET_MAILING_PLANS_DM_EXEMPT_STATUS_FILTER';
    
// Campaigns
export const TOGGLE_CAMPAIGN_SELECTION = 'TOGGLE_CAMPAIGN_SELECTION';
export const SAVE_CAMPAIGN_CONFIG = 'SAVE_CAMPAIGN_CONFIG';

// Franchise
export const GET_FRANCHISE_INFO = 'GET_FRANCHISE_INFO';
export const GET_FRANCHISE_INFO_ERROR = 'GET_FRANCHISE_INFO_ERROR';
export const FRANCHISE_INFO_CONFIRM_WARNING = 'FRANCHISE_INFO_CONFIRM_WARNING';
export const RESET_FRANCHISE_INFO = 'RESET_FRANCHISE_INFO';
export const INCORRECT_FRANCHISE_INFO_COMMENTS_REQUIRED =
    'INCORRECT_FRANCHISE_INFO_COMMENTS_REQUIRED';

// Addresses Import
export const UPLOAD_ADDRESSES_FILE = 'UPLOAD_ADDRESSES_FILE';

export const UPLOADING_ADDRESSES = 'UPLOADING_ADDRESSES';
export const SET_ADDRESSES_UPLOAD_PROGRESS = 'SET_ADDRESSES_UPLOAD_PROGRESS';
export const UPLOAD_ADDRESSES_SUCCESS = 'UPLOAD_ADDRESSES_SUCCESS';

export const SET_ADDRESSES_IMPORT_SUCCESS = 'SET_ADDRESSES_IMPORT_SUCCESS';
export const SET_ADDRESSES_IMPORT_ERROR = 'SET_ADDRESSES_IMPORT_ERROR';

export const GET_ADDRESS_IMPORTS_LOG = 'GET_ADDRESS_IMPORTS_LOG';
export const GET_ADDRESS_IMPORTS_LOG_LOADING =
    'GET_ADDRESS_IMPORTS_LOG_LOADING';
export const GET_ADDRESS_IMPORTS_LOG_ERROR = 'GET_ADDRESS_IMPORTS_LOG_ERROR';

export const DELETE_ADDRESSES_FILE = 'DELETE_ADDRESSES_FILE';
export const DELETE_ADDRESSES_FILE_LOADING = 'DELETE_ADDRESSES_FILE_LOADING';
export const DELETE_ADDRESSES_FILE_ERROR = 'DELETE_ADDRESSES_FILE_ERROR';
export const DELETE_ADDRESSES_FILE_SUCCESS = 'DELETE_ADDRESSES_FILE_SUCCESS';

export const DOWNLOAD_ADDRESSES_FILE = 'DOWNLOAD_ADDRESSES_FILE';
export const DOWNLOAD_ADDRESSES_FILE_ERROR = 'DOWNLOAD_ADDRESSES_FILE_ERROR';

// Campaign Exports
export const GET_ACTIVE_PLAN_CAMPAIGN_TEMPLATES =
    'GET_ACTIVE_PLAN_CAMPAIGN_TEMPLATES';
export const GET_ACTIVE_PLAN_CAMPAIGN_TEMPLATES_LOADING =
    'GET_ACTIVE_PLAN_CAMPAIGN_TEMPLATES_LOADING';
export const GET_ACTIVE_PLAN_CAMPAIGN_TEMPLATES_ERROR =
    'GET_ACTIVE_PLAN_CAMPAIGN_TEMPLATES_ERROR';
export const EXPORT_CAMPAIGN = 'EXPORT_CAMPAIGN';
export const EXPORT_CAMPAIGN_LOADING = 'EXPORT_CAMPAIGN_LOADING';
export const EXPORT_CAMPAIGN_ERROR = 'EXPORT_CAMPAIGN_ERROR';
export const EXPORT_CAMPAIGN_SUCCESS = 'EXPORT_CAMPAIGN_SUCCESS';
export const DOWNLOAD_CAMPAIGN_EXPORT = 'DOWNLOAD_CAMPAIGN_EXPORT';
export const DOWNLOAD_CAMPAIGN_EXPORT_LOADING =
    'DOWNLOAD_CAMPAIGN_EXPORT_LOADING';
export const DOWNLOAD_CAMPAIGN_EXPORT_ERROR = 'DOWNLOAD_CAMPAIGN_EXPORT_ERROR';

// Yearly Plans
export const GET_YEARLY_PLANS = 'GET_YEARLY_PLANS';
export const GET_YEARLY_PLANS_LOADING = 'GET_YEARLY_PLANS_LOADING';
export const GET_YEARLY_PLANS_ERROR = 'GET_YEARLY_PLANS_ERROR';

export const CREATE_YEARLY_PLAN_LOADING = 'CREATE_YEARLY_PLAN_LOADING';
export const CREATE_YEARLY_PLAN_ERROR = 'CREATE_YEARLY_PLAN_ERROR';
export const CREATE_YEARLY_PLAN_SUCCESS = 'CREATE_YEARLY_PLAN_SUCCESS';

// Plan Config
export const ON_UPDATE_PLAN_CONFIG = 'ON_UPDATE_PLAN_CONFIG';
export const RESET_PLAN_CONFIG_STATE = 'RESET_PLAN_CONFIG_STATE';

export const GET_MAILING_PLAN_TEMPLATE = 'GET_MAILING_PLAN_TEMPLATE';
export const GET_MAILING_PLAN_TEMPLATE_LOADING =
    'GET_MAILING_PLAN_TEMPLATE_LOADING';
export const GET_MAILING_PLAN_TEMPLATE_ERROR =
    'GET_MAILING_PLAN_TEMPLATE_ERROR';

export const GET_BUSINESS_RULES = 'GET_BUSINESS_RULES';
export const GET_BUSINESS_RULES_LOADING = 'GET_BUSINESS_RULES_LOADING';
export const GET_BUSINESS_RULES_ERROR = 'GET_BUSINESS_RULES_ERROR';

export const POST_NEW_CAMPAIGN_TEMPLATES = 'POST_NEW_CAMPAIGN_TEMPLATES';
export const POST_NEW_CAMPAIGN_TEMPLATES_LOADING =
    'POST_NEW_CAMPAIGN_TEMPLATES_LOADING';
export const POST_NEW_CAMPAIGN_TEMPLATES_ERROR =
    'POST_NEW_CAMPAIGN_TEMPLATES_ERROR';

export const UPDATE_CAMPAIGN_TEMPLATES = 'UPDATE_CAMPAIGN_TEMPLATES';
export const UPDATE_CAMPAIGN_TEMPLATES_LOADING =
    'UPDATE_CAMPAIGN_TEMPLATES_LOADING';
export const UPDATE_CAMPAIGN_TEMPLATES_ERROR =
    'UPDATE_CAMPAIGN_TEMPLATES_ERROR';

export const DELETE_CAMPAIGNS_FROM_TEMPLATE_LOADING =
    'DELETE_CAMPAIGNS_FROM_TEMPLATE_LOADING';
export const DELETE_CAMPAIGNS_FROM_TEMPLATE_ERROR =
    'DELETE_CAMPAIGNS_FROM_TEMPLATE_ERROR';

export const UPDATE_MAILING_PLAN_TEMPLATE = 'UPDATE_MAILING_PLAN_TEMPLATE';
export const UPDATE_MAILING_PLAN_TEMPLATE_ERROR =
    'UPDATE_MAILING_PLAN_TEMPLATE_ERROR';
export const UPDATE_MAILING_PLAN_TEMPLATE_LOADING =
    'UPDATE_MAILING_PLAN_TEMPLATE_LOADING';
export const UPDATE_MAILING_PLAN_TEMPLATE_SUCCESS =
    'UPDATE_MAILING_PLAN_TEMPLATE_SUCCESS';

export const ACTIVATE_MAILING_PLAN_TEMPLATE = 'ACTIVATE_MAILING_PLAN_TEMPLATE';
export const ACTIVATE_MAILING_PLAN_TEMPLATE_LOADING =
    'ACTIVATE_MAILING_PLAN_TEMPLATE_LOADING';
export const ACTIVATE_MAILING_PLAN_TEMPLATE_ERROR =
    'ACTIVATE_MAILING_PLAN_ERROR';
export const ACTIVATE_MAILING_PLAN_TEMPLATE_SUCCESS =
    'ACTIVATE_MAILING_PLAN_TEMPLATE_SUCCESS';

export const DELETE_MAILING_PLAN_TEMPLATE = 'DELETE_MAILING_PLAN_TEMPLATE';
export const DELETE_MAILING_PLAN_TEMPLATE_LOADING =
    'DELETE_MAILING_PLAN_TEMPLATE_LOADING';
export const DELETE_MAILING_PLAN_TEMPLATE_ERROR =
    'DELETE_MAILING_PLAN_TEMPLATE_ERROR';
export const DELETE_MAILING_PLAN_TEMPLATE_SUCCESS =
    'DELETE_MAILING_PLAN_TEMPLATE_SUCCESS';

export const GET_SEND_ORDER_OPTIONS = 'GET_SEND_ORDER_OPTIONS';
export const GET_SEND_ORDER_OPTIONS_ERROR = 'GET_SEND_ORDER_OPTIONS_ERROR';

// Brochures Config
export const GET_BROCHURES_FOR_CONFIG_LIST = 'GET_BROCHURES_FOR_CONFIG_LIST';
export const GET_BROCHURES_FOR_CONFIG_LIST_LOADING =
    'GET_BROCHURES_FOR_CONFIG_LIST_LOADING';
export const GET_BROCHURES_FOR_CONFIG_LIST_ERROR =
    'GET_BROCHURES_FOR_CONFIG_LIST_ERROR';

export const SAVE_BROCHURE_LOADING = 'SAVE_BROCHURE_LOADING';
export const SAVE_BROCHURE_ERROR = 'SAVE_BROCHURE_ERROR';
export const SAVE_BROCHURE_SUCCESS = 'SAVE_BROCHURE_SUCCESS';

export const UPDATE_BROCHURE_LOADING = 'UPDATE_BROCHURE_LOADING';
export const UPDATE_BROCHURE_ERROR = 'UPDATE_BROCHURE_ERROR';
export const UPDATE_BROCHURE_SUCCESS = 'UPDATE_BROCHURE_SUCCESS';

export const SAVE_BROCHURE_IMAGE_ERROR = 'SAVE_BROCHURE_IMAGE_ERROR';

export const DELETE_BROCHURE_LOADING = 'DELETE_BROCHURE_LOADING';
export const DELETE_BROCHURE_ERROR = 'DELETE_BROCHURE_ERROR';
export const DELETE_BROCHURE_SUCCESS = 'DELETE_BROCHURE_SUCCESS';

// Coupons Config
export const GET_COUPONS_FOR_CONFIG_LIST = 'GET_COUPONS_FOR_CONFIG_LIST';
export const GET_COUPONS_FOR_CONFIG_LIST_LOADING =
    'GET_COUPONS_FOR_CONFIG_LIST_LOADING';
export const GET_COUPONS_FOR_CONFIG_LIST_ERROR =
    'GET_COUPONS_FOR_CONFIG_LIST_ERROR';

export const SAVE_COUPON_LOADING = 'SAVE_COUPON_LOADING';
export const SAVE_COUPON_ERROR = 'SAVE_COUPON_ERROR';
export const SAVE_COUPON_SUCCESS = 'SAVE_COUPON_SUCCESS';

export const UPDATE_COUPON_LOADING = 'UPDATE_COUPON_LOADING';
export const UPDATE_COUPON_ERROR = 'UPDATE_COUPON_ERROR';
export const UPDATE_COUPON_SUCCESS = 'UPDATE_COUPON_SUCCESS';

export const DELETE_COUPON_LOADING = 'DELETE_COUPON_LOADING';
export const DELETE_COUPON_ERROR = 'DELETE_COUPON_ERROR';
export const DELETE_COUPON_SUCCESS = 'DELETE_COUPON_SUCCESS';

export const GET_COUPON_DISCOUNTS = 'GET_COUPON_DISCOUNTS';
export const GET_COUPON_DISCOUNTS_LOADING = 'GET_COUPON_DISCOUNTS_LOADING';
export const GET_COUPON_DISCOUNTS_ERROR = 'GET_COUPON_DISCOUNTS_ERROR';

export const RESET_COUPON_DISCOUNTS = 'RESET_COUPON_DISCOUNTS';

// Discounts Config
export const GET_DISCOUNTS_FOR_CONFIG_LIST = 'GET_DISCOUNTS_FOR_CONFIG_LIST';
export const GET_DISCOUNTS_FOR_CONFIG_LIST_LOADING =
    'GET_DISCOUNTS_FOR_CONFIG_LIST_LOADING';
export const GET_DISCOUNTS_FOR_CONFIG_LIST_ERROR =
    'GET_DISCOUNTS_FOR_CONFIG_LIST_ERROR';

export const SAVE_DISCOUNT_LOADING = 'SAVE_DISCOUNT_LOADING';
export const SAVE_DISCOUNT_ERROR = 'SAVE_DISCOUNT_ERROR';
export const SAVE_DISCOUNT_SUCCESS = 'SAVE_DISCOUNT_SUCCESS';

export const UPDATE_DISCOUNT_LOADING = 'UPDATE_DISCOUNT_LOADING';
export const UPDATE_DISCOUNT_ERROR = 'UPDATE_DISCOUNT_ERROR';
export const UPDATE_DISCOUNT_SUCCESS = 'UPDATE_DISCOUNT_SUCCESS';

export const DELETE_DISCOUNT_LOADING = 'DELETE_DISCOUNT_LOADING';
export const DELETE_DISCOUNT_ERROR = 'DELETE_DISCOUNT_ERROR';
export const DELETE_DISCOUNT_SUCCESS = 'DELETE_DISCOUNT_SUCCESS';

// Services Config
export const GET_SERVICES_FOR_CONFIG_LIST = 'GET_SERVICES_FOR_CONFIG_LIST';
export const GET_SERVICES_FOR_CONFIG_LIST_LOADING =
    'GET_SERVICES_FOR_CONFIG_LIST_LOADING';
export const GET_SERVICES_FOR_CONFIG_LIST_ERROR =
    'GET_SERVICES_FOR_CONFIG_LIST_ERROR';

export const SAVE_SERVICE_LOADING = 'SAVE_SERVICE_LOADING';
export const SAVE_SERVICE_ERROR = 'SAVE_SERVICE_ERROR';
export const SAVE_SERVICE_SUCCESS = 'SAVE_SERVICE_SUCCESS';

export const UPDATE_SERVICE_LOADING = 'UPDATE_SERVICE_LOADING';
export const UPDATE_SERVICE_ERROR = 'UPDATE_SERVICE_ERROR';
export const UPDATE_SERVICE_SUCCESS = 'UPDATE_SERVICE_SUCCESS';

export const DELETE_SERVICE_LOADING = 'DELETE_SERVICE_LOADING';
export const DELETE_SERVICE_ERROR = 'DELETE_SERVICE_ERROR';
export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS';

// Badges Config
export const GET_BADGES_FOR_CONFIG_LIST = 'GET_BADGES_FOR_CONFIG_LIST';
export const GET_BADGES_FOR_CONFIG_LIST_LOADING =
    'GET_BADGES_FOR_CONFIG_LIST_LOADING';
export const GET_BADGES_FOR_CONFIG_LIST_ERROR =
    'GET_BADGES_FOR_CONFIG_LIST_ERROR';

export const SAVE_BADGE_LOADING = 'SAVE_BADGE_LOADING';
export const SAVE_BADGE_ERROR = 'SAVE_BADGE_ERROR';
export const SAVE_BADGE_SUCCESS = 'SAVE_BADGE_SUCCESS';

export const UPDATE_BADGE_LOADING = 'UPDATE_BADGE_LOADING';
export const UPDATE_BADGE_ERROR = 'UPDATE_BADGE_ERROR';
export const UPDATE_BADGE_SUCCESS = 'UPDATE_BADGE_SUCCESS';

export const SAVE_BADGE_IMAGE_ERROR = 'SAVE_BADGE_IMAGE_ERROR';

export const DELETE_BADGE_LOADING = 'DELETE_BADGE_LOADING';
export const DELETE_BADGE_ERROR = 'DELETE_BADGE_ERROR';
export const DELETE_BADGE_SUCCESS = 'DELETE_BADGE_SUCCESS';

// Badges
export const GET_BADGES = 'GET_BADGES';
export const GET_BADGES_LOADING = 'GET_BADGES_LOADING';
export const GET_BADGES_ERROR = 'GET_BADGES_ERROR';
export const GET_BADGE_COUPONS_ERROR = 'GET_BADGE_COUPONS_ERROR';

// Brochures
export const GET_BROCHURES = 'GET_BROCHURES';
export const GET_BROCHURES_LOADING = 'GET_BROCHURES_LOADING';
export const GET_BROCHURES_ERROR = 'GET_BROCHURES_ERROR';
export const GET_BROCHURE_COUPONS_ERROR = 'GET_BROCHURE_COUPONS_ERROR';

// Coupons
export const GET_COUPONS = 'GET_COUPONS';
export const GET_COUPONS_LOADING = 'GET_COUPONS_LOADING';
export const GET_COUPONS_ERROR = 'GET_COUPONS_ERROR';

// Discounts
export const GET_DISCOUNTS = 'GET_DISCOUNTS';
export const GET_DISCOUNTS_LOADING = 'GET_DISCOUNTS_LOADING';
export const GET_DISCOUNTS_ERROR = 'GET_DISCOUNTS_ERROR';

// Reports
export const GET_CAMPAIGNS_REPORT = 'GET_CAMPAIGNS_REPORT';
export const GET_CAMPAIGNS_REPORT_LOADING = 'GET_CAMPAIGNS_REPORT_LOADING';
export const GET_CAMPAIGNS_REPORT_ERROR = 'GET_CAMPAIGNS_REPORT_ERROR';

// Notifications
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const GENERIC_NOTIFICATION = 'GENERIC_NOTIFICATION';
export const CONFIRM_EMAIL_REMINDERS = 'CONFIRM_EMAIL_REMINDERS';
export const CONFIRM_EMAIL_REMINDERS_ERROR = 'CONFIRM_EMAIL_REMINDERS_ERROR';
export const CONFIRM_EMAIL_REMINDERS_SUCCESS = 'CONFIRM_EMAIL_SUCCESS';

// LMPBP Notifications
export const LMPBP_GET_BUDGET_PLANS_ERROR = 'LMPBP_GET_BUDGET_PLANS_ERROR';
export const LMPBP_GET_BUDGET_HISTORIC_ERROR =
    'LMPBP_GET_BUDGET_HISTORIC_ERROR';
export const LMPBP_GET_PLAN_TEMPLATES_ERROR = 'LMPBP_GET_PLAN_TEMPLATES_ERROR';
export const LMPBP_GET_TACTICS_ERROR = 'LMPBP_GET_TACTICS_ERROR';
export const LMPBP_ADD_FRANCHISE_CUSTOM_TACTIC_ERROR = 'LMPBP_ADD_FRANCHISE_CUSTOM_TACTIC_ERROR';
export const LMPBP_ADD_FRANCHISE_CUSTOM_TACTIC_SUCCESS = 'LMPBP_ADD_FRANCHISE_CUSTOM_TACTIC_SUCCESS';
export const LMPBP_DELETE_FRANCHISE_CUSTOM_TACTIC_ERROR = 'LMPBP_ADD_FRANCHISE_CUSTOM_TACTIC_ERROR';
export const LMPBP_DELETE_FRANCHISE_CUSTOM_TACTIC_SUCCESS = 'LMPBP_ADD_FRANCHISE_CUSTOM_TACTIC_SUCCESS';
export const LMPBP_SAVE_ALL_ENTRIES_ERROR = 'LMPBP_SAVE_ALL_ENTRIES_ERROR';
export const LMPBP_SAVE_ALL_ENTRIES_SUCCESS = 'LMPBP_SAVE_ALL_ENTRIES_SUCCESS';
export const LMPBP_CREATE_BUDGET_PLAN_ERROR = 'LMPBP_CREATE_BUDGET_PLAN_ERROR';
export const LMPBP_GET_BUDGET_ENTRY_ERROR = 'LMPBP_GET_BUDGET_ENTRY_ERROR';
export const LMPBP_SUBMIT_BUDGET_PLAN_ERROR = 'LMPBP_SUBMIT_BUDGET_PLAN_ERROR';
export const LMPBP_SUBMIT_BUDGET_PLAN_SUCCESS =
    'LMPBP_SUBMIT_BUDGET_PLAN_SUCCESS';
export const LMPBP_SUBMIT_BUDGET_PLAN_SUCCESS_INFO =
    'LMPBP_SUBMIT_BUDGET_PLAN_SUCCESS_INFO'
export const LMPBP_APPROVE_BUDGET_PLAN_ERROR =
    'LMPBP_APPROVE_BUDGET_PLAN_ERROR';
export const LMPBP_APPROVE_BUDGET_PLAN_SUCCESS =
    'LMPBP_APPROVE_BUDGET_PLAN_SUCCESS';
export const LMPBP_REJECT_BUDGET_PLAN_ERROR = 'LMPBP_REJECT_BUDGET_PLAN_ERROR';
export const LMPBP_REJECT_BUDGET_PLAN_SUCCESS =
    'LMPBP_REJECT_BUDGET_PLAN_SUCCESS';
export const LMPBP_COPY_BUDGET_PLAN_ERROR = 'LMPBP_COPY_BUDGET_PLAN_ERROR';
export const LMPBP_GET_FRANCHISES_ERROR = 'LMPBP_GET_FRANCHISES_ERROR';
export const LMPBP_GET_STATUS_ERROR = 'LMPBP_GET_STATUS_ERROR';
export const LMPBP_GET_FRANCHISES_OWNERS_ERROR =
    'LMPBP_GET_FRANCHISES_OWNERS_ERROR';
export const LMPBP_GET_EXPORT_FILE_ERROR = 'LMPBP_GET_EXPORT_FILE_ERROR';
export const LMPBP_SELECT_NULL_PLAN = 'LMPBP_SELECT_NULL_PLAN';
export const LMPBP_SELECT_ONLY_NULL_PLANS = 'LMPBP_SELECT_ONLY_NULL_PLANS';
export const LMPBP_GET_EXPORTED_FILES_ERROR = 'LMPBP_GET_EXPORTED_FILES_ERROR';

// Confirmation Modal
export const LMPBP_MOVE_TO = 'LMPBP_MOVE_TO';
export const LMPBP_SET_IS_LEAVING_PAGE = 'LMPBP_SET_IS_LEAVING_PAGE';
export const LMPBP_UNSAVED_CHANGES_ERROR = 'LMPBP_UNSAVED_CHANGES_ERROR';
export const LMPBP_CONFIRM_MODAL_ACCEPTED = 'LMPBP_CONFIRM_MODAL_ACCEPTED';
export const LMPBP_SET_HAVE_UNSAVED_CHANGES = 'LMPBP_SET_HAVE_UNSAVED_CHANGES';

//  Export Plan
export const EXPORT_PLAN_ERROR = 'EXPORT_PLAN_ERROR';
export const EXPORT_PLAN_SUCCESS = 'EXPORT_PLAN_SUCCESS';
export const DOWNLOAD_PLAN_EXPORT_ERROR = 'DOWNLOAD_PLAN_EXPORT_ERROR';
export const GET_ACTIVE_PLAN_TEMPLATES_ERROR = 'GET_ACTIVE_PLAN_TEMPLATES_ERROR';
export const DOWNLOAD_PLAN_EXPORT = 'DOWNLOAD_PLAN_EXPORT';
export const DOWNLOAD_PLAN_EXPORT_LOADING = 'DOWNLOAD_PLAN_EXPORT_LOADING';
export const EXPORT_PLAN = 'EXPORT_PLAN';
export const EXPORT_PLAN_LOADING = 'EXPORT_PLAN_LOADING';
export const GET_ACTIVE_PLAN_TEMPLATES = 'GET_ACTIVE_PLAN_TEMPLATES';
export const GET_ACTIVE_PLAN_TEMPLATES_LOADING = 'GET_ACTIVE_PLAN_TEMPLATES_LOADING'
export const EXPORT_PLAN_LIST_ERROR = 'EXPORT_PLAN_LIST_ERROR';
export const EXPORT_PLAN_LIST_SUCCESS = 'EXPORT_PLAN_LIST_SUCCESS';
export const EXPORT_PLAN_LIST = 'EXPORT_PLAN_LIST';
export const EXPORT_PLAN_LIST_LOADING = 'EXPORT_PLAN_LIST_LOADING';
export const LMPBP_GET_RESCOM_ERROR = 'LMPBP_GET_RESCOM_ERROR';
export const LMPBP_GET_AWS_IOT_CREDENTIALS_ERROR = 'LMPBP_GET_IOT_ENDPOINT_ERROR';
export const LMPBP_AWS_IOT_TIMEOUT_ERROR = 'LMPBP_GET_IOT_ENDPOINT_ERROR';

// Utilies
export const SET_CURRENT_ROUTE = 'SET_CURRENT_ROUTE';
export const LMPBP_SET_MODIFIED_TACTIC = 'LMPBP_SET_MODIFIED_TACTIC';
export const LMPBP_CLEAN_MODIFIED_TACTICS = 'LMPBP_CLEAN_MODIFIED_TACTICS';

//Franconnect sync
export const LMPBP_GET_SYNC_FRAN_FRANCHISES_ERROR = 'LMPBP_GET_SYNC_FRAN_FRANCHISES_ERROR';
export const LMPBP_GET_SYNC_FRAN_FRANCHISES_SUCCESS = 'LMPBP_GET_SYNC_FRAN_FRANCHISES_SUCCESS';
export const LMPBP_GET_SYNC_FRAN_FRANCHISEES_ERROR = 'LMPBP_GET_SYNC_FRAN_FRANCHISEES_ERROR';
export const LMPBP_GET_SYNC_FRAN_FRANCHISEES_SUCCESS = 'LMPBP_GET_SYNC_FRAN_FRANCHISEES_SUCCESS';
export const LMPBP_UPDATE_LOCKDATE_REMINDER_ERROR = 'LMPBP_UPDATE_LOCKDATE_REMINDER_ERROR';
export const LMPBP_UPDATE_LOCKDATE_REMINDER_SUCCESS = 'LMPBP_UPDATE_LOCKDATE_REMINDER_SUCCESS';

// Dm toggle lock date reminder
export const GET_LOCK_DATE_REMINDER_SCCESS = 'GET_LOCK_DATE_REMINDER_SCCESS';
export const GET_LOCK_DATE_REMINDER_LOADNING = 'GET_LOCK_DATE_REMINDER_LOADNING';
export const GET_LOCK_DATE_REMINDER_ERROR = 'GET_LOCK_DATE_REMINDER_ERROR';