import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SubmitConfirmation from './SubmitConfirmation';
import SaveDraftConfirmation from './SaveDraftConfirmation';
import Button from 'components/common/button/Button';
import BusinessRulesList from './BusinessRulesList';

import userSession from 'utils/userSession';
import formatCurrency from 'utils/formatCurrency';
import validateMailingPlan from 'utils/mailing-plan/validateMailingPlan';
import validateStatus from 'utils/validateStatus';
import { 
    confirmMailingPlanSubmission as confirmMailingPlanSubmissionAction,
    confirmMailingPlanSaveDraft as confirmMailingPlanSaveDraftAction,
    submitMailingPlan as submitMailingPlanAction,
    saveDraftMailingPlan as saveDraftMailingPlanAction,
    copyDraftMailingPlan as copyDraftMailingPlanAction,
    saveOwnerDetails as saveOwnerDetailsAction
} from 'actions/mailingPlanActions';
import { setHaveUnsavedChangesAction, setMoveToAction
} from 'actions/statusAccions';
import ConfirmationModal from 'components/common/confirmation-modal/ConfirmationModal';
import { 
    getMailingPlan as getMailingPlanAction 
} from 'actions/mailingPlanActions';

export class HeaderFO extends Component {

    static propTypes = {
        mailingPlan: PropTypes.object,
        franchiseInfoConfirmed: PropTypes.bool,
        correctFranchiseInfo: PropTypes.bool,
        calculateTotalCost: PropTypes.func
    }

    dismissSubmissionModal = () => {
        const {
            mailingPlan,
            franchiseInfo
        } = this.props;
        const franchiseId = franchiseInfo?.franchiseId;
        this.props.confirmMailingPlanSubmission(false);
        this.props.confirmMailingPlanSaveDraft(false);
        if(userSession.isDivisionalUser() && validateStatus.isSubmitted(mailingPlan?.status)){
            window.location.href=`/mailing-plan/${franchiseId}/${mailingPlan?.mailingPlanId}/${mailingPlan?.mailingPlanYear}`;
        }
    }

    enablePlanSubmission = () => {
        const {
            mailingPlan,
            franchiseInfoConfirmed,
            commentsRequired
        } = this.props;

        if(!mailingPlan?.mailingPlanId || mailingPlan?.loading) return false;
        if(!franchiseInfoConfirmed) return false;
        if(commentsRequired && !mailingPlan?.comments) return false;

        return validateMailingPlan(mailingPlan).enablePlanSubmission;
    }

    onSubmit = () => {
        const {
            mailingPlan,
            franchiseInfo,
            correctFranchiseInfo,
            submitMailingPlan,
            saveOwnerDetails,
            setHaveUnsavedChanges,
            setMoveTo,
            haveUnsavedChanges
        } = this.props;
        if(!haveUnsavedChanges && userSession.isFranchiseOwer()){
            setMoveTo('not submit');
            return false;
        }
        setHaveUnsavedChanges(false);
        setMoveTo(null);
        const brandId = userSession.getBrandId();
        const franchiseId = franchiseInfo?.franchiseId;
        const { enablePlanSubmission } = validateMailingPlan(mailingPlan);
        
        if(enablePlanSubmission) {
            saveOwnerDetails(franchiseId, mailingPlan);
            submitMailingPlan(brandId, franchiseId, mailingPlan, correctFranchiseInfo);
        }
    }

    onSave = () => {
        const {
            mailingPlan,
            franchiseInfo,
            correctFranchiseInfo,
            saveDraftMailingPlan,
            copyDraftMailingPlan,
            saveOwnerDetails,
            setHaveUnsavedChanges,
            setMoveTo
        } = this.props;

        setHaveUnsavedChanges(false);
        setMoveTo(null);
        const brandId = userSession.getBrandId();
        const franchiseId = franchiseInfo?.franchiseId;
        const { enablePlanSubmission } = validateMailingPlan(mailingPlan);
        
        if(enablePlanSubmission) {
            saveOwnerDetails(franchiseId, mailingPlan);
            //If Mailing plan is not created before
            if(mailingPlan?.isTemplate){
                copyDraftMailingPlan(brandId, franchiseId,mailingPlan,correctFranchiseInfo);
            }else{
                saveDraftMailingPlan(brandId, franchiseId, mailingPlan, correctFranchiseInfo);
            }
        }
    }
    
    moveAway = () => {
        const { 
            moveTo,
            getMailingPlan
        } = this.props;
     
        switch (true) {
            case  moveTo.includes('Logout'):
                userSession.logout();
                window.open(process.env.REACT_APP_LOGOUT_URL, "_self");
                break;
            case  moveTo.includes('Dashboard'):
                window.location.href=`/`;
                break;
            case moveTo.includes('switch plan-'):
                let mailingPlanId = moveTo.split('-').pop();
                getMailingPlan(
                    userSession.getBrandId(), 
                    userSession.getFranchiseId(), 
                    mailingPlanId
                );
                break;
        }
    }
    render() {
        const {
            mailingPlan,
            calculateTotalCost,
            haveUnsavedChanges,
            moveTo
        } = this.props;
        return (
            <div className='mailing-plan-header-container'>
                { !mailingPlan?.loading && 
                <SubmitConfirmation
                    onHide={this.dismissSubmissionModal}
                    show={mailingPlan?.submitted}
                    planYear={Number(mailingPlan?.mailingPlanYear)}
                />
                }
                 <SaveDraftConfirmation
                    onHide={this.dismissSubmissionModal}
                    show={mailingPlan?.saved}
                    planYear={Number(mailingPlan?.mailingPlanYear)}
                />
                <BusinessRulesList />
                <div className='d-flex align-items-center'>
                    <span className='mr-4 mailing-plan-total-spend'>
                        Total annual spend = ${formatCurrency(calculateTotalCost())}
                    </span>
                    <div className='d-flex flex-column'>
                        <div className='d-flex column-gap-1'>
                            { !validateStatus.isSubmitted(mailingPlan?.status) && !validateStatus.isApproved(mailingPlan?.status) &&  !validateStatus.isRejected(mailingPlan?.status)? (
                            <>
                            <Button
                                            btnType='primary'
                                            loading={mailingPlan?.saving}
                                            loadingMessage={'Saving...'}
                                            disabled={!this.enablePlanSubmission() ||
                                                mailingPlan?.loading}
                                            action={() => this.onSave()}
                                        >
                                            Save
                                        </Button><Button
                                            btnType='primary'
                                            loading={mailingPlan?.submitting}
                                            loadingMessage={'Sending request...'}
                                            disabled={!this.enablePlanSubmission() ||
                                                mailingPlan?.loading}
                                            action={() => this.onSubmit()}
                                        >
                                                Submit Request
                                            </Button></>
                    
                            ) : null}
                            
                        </div>
                    </div>
                </div>
                <ConfirmationModal
                    show={haveUnsavedChanges && moveTo}
                    centered
                    type='warning'
                    headerType='warning'
                    headerIconLeft='fas fa-exclamation-triangle'
                    headerIconRight='fas fa-exclamation-triangle'
                    header='Unsaved changes'
                    confirmBtnLabel='Navigate away'
                    showConfirmBtn={true}
                    showCancelBtn={true}
                    onConfirm={this.moveAway}
                    message='Your plan has unsaved changes, are you sure you want to navigate away?'
                />
                <ConfirmationModal
                    show={!haveUnsavedChanges && moveTo == 'not submit'}
                    centered
                    type='warning'
                    headerType='warning'
                    headerIconLeft='fas fa-exclamation-triangle'
                    headerIconRight='fas fa-exclamation-triangle'
                    header='Warning'
                    confirmBtnLabel='Ok'
                    showConfirmBtn={true}
                    showCancelBtn={false}
                    onConfirm={this.moveAway}
                    message='Please make sure modifications are made to submit the plan'
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    franchiseInfo: state.franchise,
    commentsRequired: state.franchise?.commentsRequired,
    haveUnsavedChanges: state.status.unsavedBudgetPlanChanges,
    moveTo: state.status.moveTo,
});

export default connect(mapStateToProps, {
    confirmMailingPlanSubmission: confirmMailingPlanSubmissionAction,
    confirmMailingPlanSaveDraft:confirmMailingPlanSaveDraftAction,
    submitMailingPlan: submitMailingPlanAction,
    saveDraftMailingPlan: saveDraftMailingPlanAction,
    copyDraftMailingPlan: copyDraftMailingPlanAction,
    saveOwnerDetails: saveOwnerDetailsAction,
    setHaveUnsavedChanges: setHaveUnsavedChangesAction,
    setMoveTo: setMoveToAction,
    getMailingPlan: getMailingPlanAction,
})(HeaderFO);
