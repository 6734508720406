import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import CampaignsConfigList from './campaigns-config-list/CampaignsConfigList';
import RulesConfigList from './rules-config-list/RulesConfigList';
import Input from 'components/common/input/Input';
import MultiSelect from 'components/common/multi-select/MultiSelect';
import Button from 'components/common/button/Button';
import ConfirmationModal from 'components/common/confirmation-modal/ConfirmationModal';
import Switch from 'components/common/switch/Switch';
import Loader from 'components/common/loader/Loader';

import { 
    deleteMailingPlanTemplate as deleteMailingPlanTemplateAction,
    getMailingPlanTemplate as getMailingPlanTemplateAction,
    getSendOrderOptions as getSendOrderOptionsAction,
    onUpdatePlanConfig as onUpdatePlanConfigAction,
    saveMailingPlanConfig as saveMailingPlanConfigAction
} from 'actions/planConfigActions';
import userSession from 'utils/userSession';
import MultiInputForm from 'components/common/multi-input/MultiInput';

export class PlanConfig extends Component {
    state = {
        templateId: null,
        showDeleteConfirmation: false,
        showActivationConfirmation: false,
        sendOrder: [],
        sendOrderOptions: []
    }

    static propTypes = {
        templateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }

    isSaveEnabled = () => {
        const { planTemplate } = this.props;
        const minCampaigns = planTemplate?.businessRules.find((template) => template.key === 'minimumCampaigns');

        if(!minCampaigns.value.length && minCampaigns.active) return false;
        if(!planTemplate || !Object.keys(planTemplate).length) return false;

        if(
           !userSession.isMSQUser() && !userSession.isMTUser() && ( !Array.isArray(planTemplate.sendOrder) ||
            planTemplate.sendOrder.length <= 0 )
        ) {
            return false;
        }
        if(
            !Array.isArray(planTemplate.campaignTemplates) ||
            planTemplate.campaignTemplates.length <= 0
        ) {
            return false;
        }
        const { planYear, costPerPiece, pieceSizes } = planTemplate;
        if (userSession.isMSQUser()) {
           return !pieceSizes.some((pieceSize) => !pieceSize.size || !pieceSize.price ) && planYear && pieceSizes?.length > 0;
        }

        return planYear && costPerPiece;
    }

    onActivatePlan = checked => {
        if(checked) {
            this.setState({ showActivationConfirmation: true });
        } else {
            this.onActivationConfirmed(false);
        }
    }

    onActivationConfirmed = (active = false) => {
        const { 
            planTemplate,
            onUpdatePlanConfig
        } = this.props;

        this.setState({ showActivationConfirmation: false }, () => {
            onUpdatePlanConfig({ ...planTemplate, active });
        });
    }

    onActivationCanceled = () => {
        this.setState({ showActivationConfirmation: false });
    }

    onSaveMailingPlan = () => {
        const {
            planTemplate,
            saveMailingPlanConfig
        } = this.props;

        let updatedTemplate = { ...planTemplate, sendOrder: this.state.sendOrder};
        
        saveMailingPlanConfig(updatedTemplate);
    }

    sortCampaigns = (campaigns = []) => {
        if(!campaigns && !campaigns.length) return [];

        return campaigns.sort((a, b) => {
            return new moment(a.date).valueOf() - new moment(b.date).valueOf();
        });
    }

    generateSendOrderOptions = () => {
        return this.state.sendOrderOptions;
    }

    onUpdateBusinessRules = updatedRules => {
        const {
            planTemplate,
            onUpdatePlanConfig
        } = this.props;

        onUpdatePlanConfig({ 
            ...planTemplate,
            businessRules: [...updatedRules] 
        });
    }

    onAddCampaign = campaign => {
        const {
            planTemplate,
            onUpdatePlanConfig
        } = this.props;

        onUpdatePlanConfig({ 
            ...planTemplate,
            campaignTemplates: this.sortCampaigns([
                ...planTemplate?.campaignTemplates || [], 
                { ...campaign, isNew: true }
            ])
        });
    }

    onEditCampaign = editedCampaign => {
        const {
            planTemplate,
            onUpdatePlanConfig
        } = this.props;

        const { campaignTemplates } = planTemplate;
         
        let updatedCampaignTemplates = [...campaignTemplates];
        updatedCampaignTemplates.forEach((campaignTemplate, i) => {
            if(campaignTemplate.id === editedCampaign.id) {
                updatedCampaignTemplates[i] = { ...editedCampaign, isEdited: !editedCampaign.isNew }
            }
        });

        onUpdatePlanConfig({ 
            ...planTemplate,
            campaignTemplates: this.sortCampaigns([
                ...updatedCampaignTemplates
            ])
        });
    }

    onRemoveCampaign = (flaggedCampaign, willRemove = true) => {
        const { 
            planTemplate,
            onUpdatePlanConfig
        } = this.props;

        const { campaignTemplates } = planTemplate;

        let updatedCampaignTemplates = [...campaignTemplates];

        if(!flaggedCampaign?.isNew) {
            updatedCampaignTemplates.forEach((campaignTemplate, i) => {
                if(campaignTemplate.id === flaggedCampaign.id) {
                    updatedCampaignTemplates[i] = { ...flaggedCampaign, markedForRemoval: willRemove }
                }
            });
        } else {
            updatedCampaignTemplates = updatedCampaignTemplates.filter(campaign => {
                if(campaign.id === flaggedCampaign.id) return false;
                return true;
            });
        }

        onUpdatePlanConfig({ 
            ...planTemplate,
            campaignTemplates: this.sortCampaigns([
                ...updatedCampaignTemplates
            ])
        });
    }

    onUpdatePlanYear = (planYear = null) => {
        const {
            planTemplate,
            onUpdatePlanConfig,
            yearlyPlanTemplates,
            onIsUniqueYear
        } = this.props;

        onUpdatePlanConfig({ 
            ...planTemplate,
            planYear
        });

        let isUnique = true;

        yearlyPlanTemplates?.forEach(plan => {
            if(plan.planYear === Number(planYear)) isUnique = false;
        });

        onIsUniqueYear(isUnique);
    }

    onUpdateCostPerPiece = (costPerPiece) => {
        const {
            planTemplate,
            onUpdatePlanConfig
        } = this.props;

        onUpdatePlanConfig({
            ...planTemplate,
            costPerPiece: costPerPiece,
            pieceSizes: [{size:'5x11' ,price: costPerPiece}]
        });
    }

    onUpdatePieceSizes = (pieceSizes) => {
        const {
            planTemplate,
            onUpdatePlanConfig
        } = this.props;


        onUpdatePlanConfig({
            ...planTemplate,
            pieceSizes,
        });
    }

    onDeletePlan = () => {
        this.setState({ showDeleteConfirmation: true });
    }

    onConfirmPlanDelete = () => {
        const { 
            planTemplate,
            deleteMailingPlanTemplate 
        } = this.props;

        deleteMailingPlanTemplate(planTemplate?.id, planTemplate?.planYear);
    }

    onCancelPlanDelete = () => {
        this.setState({ showDeleteConfirmation: false });
    }

    onUpdateSendOrder = () => {
        const {
            planTemplate,
            onUpdatePlanConfig
        } = this.props;
        const {
            sendOrder,
        } = this.state;

        onUpdatePlanConfig({
            ...planTemplate,
            sendOrder
        });
    }

    renderHeader = () => {
        const {
            planTemplate
        } = this.props;

        const { loadingPlanTemplate } = this.props;

        if(loadingPlanTemplate) return '';

        return `Plan for ${planTemplate?.planYear}`
    }

    componentDidMount() {
        const {
            templateId,
            getMailingPlanTemplate
        } = this.props;
        let selectedSendOrders;

        getMailingPlanTemplate(templateId).then(
            (data) => {
                this.setState({...this.state, sendOrder: data?.payload?.sendOrder});
            }
        );

        this.props.getSendOrderOptions().then((data) => {
            this.setState({...this.state, sendOrderOptions: data.payload});
        });
    }

    render() {
        const {
            showDeleteConfirmation,
            showActivationConfirmation,
            sendOrder,
            sendOrderOptions
        } = this.state;

        const { 
            planTemplate,
            deletingPlanTemplate,
            updatingPlanTemplate,
            loadingPlanTemplate,
            activatingMailingPlan,
            pendingChanges
        } = this.props;

        return (
            <Fragment>
                {
                    showDeleteConfirmation &&
                        <ConfirmationModal 
                            show={showDeleteConfirmation}
                            onClose={() => this.onCancelPlanDelete()}
                            centered
                            type='warning'
                            headerType='warning'
                            headerIconLeft='fas fa-exclamation-triangle'
                            headerIconRight='fas fa-exclamation-triangle'
                            header='Delete Mailing Plan?'
                            confirmBtnLabel='Delete'
                            showConfirmBtn={true}
                            showCancelBtn={true}
                            isLoading={deletingPlanTemplate}
                            onLoadingComplete={() => this.onCancelPlanDelete()}
                            onConfirm={() => this.onConfirmPlanDelete(true)}
                            message='Are you sure you want to delete this plan? Deleting this mailing plan will remove it from your yearly plans list.'
                        />
                }
                {
                    showActivationConfirmation && 
                        <ConfirmationModal 
                            show={showActivationConfirmation}
                            onClose={() => this.onActivationCanceled()}
                            centered
                            type='info'
                            headerType='warning'
                            headerIconLeft='fas fa-exclamation-triangle'
                            headerIconRight='fas fa-exclamation-triangle'
                            header='Enable Mailing Plan?'
                            confirmBtnLabel='Activate plan'
                            showConfirmBtn={true}
                            showCancelBtn={true}
                            isLoading={activatingMailingPlan}
                            onLoadingComplete={() => this.onActivationCanceled()}
                            onConfirm={() => this.onActivationConfirmed(true)}
                            message={
                                <div>
                                    Enabling this Mailing Plan will disable any other active plan and will make this plan available for Franchise Owners to submit their new mailing plans.
                                    <small className='d-block mt-3'>This change will not take effect until your save your changes</small>
                                </div>
                            }
                        />
                }
                {
                    (loadingPlanTemplate || updatingPlanTemplate) ?
                        <Loader loadingMessage={loadingPlanTemplate ? 'Loading mailing plan...' : 'Saving changes...'}/>
                    :
                        <div className='pr-2 pl-2'>
                            <div className='d-flex flex-wrap pt-3 pb-3 border-bottom row-gap-1 column-gap-1'>
                                <div className='flex-grow-1'>
                                    <Input
                                        label='Year'
                                        value={planTemplate?.planYear || ''}
                                        type='number'
                                        placeholder='Mailing year'
                                        onChange={({ target: { value } }) => {
                                            this.onUpdatePlanYear(value);
                                        }}
                                        errorMsg={'Please enter a mailing year for this plan'}
                                        required
                                        disabled
                                    />
                                </div>
                                <div className='flex-grow-1'>
                                    { userSession.isMSQUser() 
                                        ? <MultiInputForm elements={planTemplate?.pieceSizes} onUpdateElements={this.onUpdatePieceSizes}/>
                                        : <Input 
                                            label='Cost per piece'
                                            value={planTemplate?.costPerPiece || ''}
                                            type='number'
                                            placeholder='Cost per piece'
                                            onChange={({ target: { value } }) => {
                                                this.onUpdateCostPerPiece(value);
                                            }}
                                            error={!planTemplate?.costPerPiece}
                                            errorMsg={'Please enter a cost per piece'}
                                            required
                                        />
                                    }
                                </div>
                                <div className='flex-grow-2'>
                                    <MultiSelect 
                                        label='Send order'
                                        selectedOptions={sendOrder}
                                        options={this.generateSendOrderOptions()}
                                        enableSelection={true}
                                        onChange={(selected) => {
                                                this.setState({...this.state, sendOrder: selected}, () => {
                                                    this.onUpdateSendOrder();
                                                });
                                            }
                                        } 
                                        error={  !userSession.isMSQUser() && !userSession.isMTUser() && !sendOrder.length }
                                        errorMsg={'Please select the send order'}
                                    />
                                </div>
                                <div className='d-flex align-items-center column-gap-2'>
                                    <Switch 
                                        id='mailingPlanEditActive'
                                        label='Active'
                                        checked={planTemplate?.active}
                                        onToggle={checked => {
                                            if(!planTemplate?.currentlyActive) {
                                                this.onActivatePlan(checked)
                                            }
                                        }}
                                        tooltip={planTemplate?.currentlyActive ? 'This plan is currently active and cannot be toggled off.' : ''}
                                    />
                                    <Button
                                        btnType='primary'
                                        label='Save Changes'
                                        action={() => this.onSaveMailingPlan()}
                                        loadingMessage='Saving...'
                                        loading={updatingPlanTemplate}
                                        disabled={
                                            loadingPlanTemplate ||
                                            updatingPlanTemplate ||
                                            !pendingChanges ||
                                            !this.isSaveEnabled()
                                        }
                                    />
                                </div>
                            </div>
                            <div className='d-flex column-gap-2 border-bottom pb-3'>
                                <div className='w-50'>
                                    <CampaignsConfigList
                                        campaignTemplates={planTemplate?.campaignTemplates}
                                        onAddCampaign={this.onAddCampaign}
                                        onEditCampaign={this.onEditCampaign}
                                        onRemoveCampaign={this.onRemoveCampaign}
                                    />
                                </div>
                                <div className='w-50'>
                                    <RulesConfigList
                                        planBusinessRules={planTemplate?.businessRules}
                                        onUpdateBusinessRules={this.onUpdateBusinessRules}
                                    />
                                </div>
                            </div>
                            <div className='w-100 mt-3'>
                                <Button
                                    label='Delete'
                                    btnType='outline-danger'
                                    action={() => this.onDeletePlan()}
                                    loading={deletingPlanTemplate}
                                    loadingMessage='Deleting plan...'
                                    disabled={planTemplate?.active}
                                />
                            </div>
                        </div>
                }
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    planTemplate: state.planConfig?.mailingPlanTemplate,
    deletingPlanTemplate: state.planConfig?.deletingMailingPlanTemplate,
    updatingPlanTemplate: state.planConfig?.updatingMailingPlanTemplate,
    loadingPlanTemplate: state.planConfig?.loadingMailingPlanTemplate,
    activatingMailingPlan: state.planConfig?.activatingMailingPlan,
    pendingChanges: state.planConfig?.pendingChanges
});

export default connect(mapStateToProps, {
    getMailingPlanTemplate: getMailingPlanTemplateAction,
    getSendOrderOptions: getSendOrderOptionsAction,
    deleteMailingPlanTemplate: deleteMailingPlanTemplateAction,
    onUpdatePlanConfig: onUpdatePlanConfigAction,
    saveMailingPlanConfig: saveMailingPlanConfigAction
})(PlanConfig);
