import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Modal, ProgressBar } from 'react-bootstrap';
import Button from 'components/common/button/Button';
import InlineNotification from 'components/common/inline-notification/InlineNotification';
import Loader from 'components/common/loader/Loader';

import {
    uploadAddressesFile as uploadAddressesFileAction,
    setUploadingAddresses as setUploadingAddressesAction,
    setAddressesImportSuccess as setAddressesImportSuccessAction,
    setAddressesImportError as setAddressesImportErrorAction,
} from 'actions/importAddressesActions';
export class ImportAddressesModal extends Component {
    state = {
        fileName: null,
        notification: null,
        processingFile: false
    };

    static propTypes = {
        show: PropTypes.bool,
        onClose: PropTypes.func,
        iotEndpoint: PropTypes.string
    };

    onCloseModal = () => {
        const {
            onClose,
            uploadingAddresses,
            setAddressesImportSuccess,
            setAddressesImportError,
        } = this.props;

        if (!uploadingAddresses) {
            setAddressesImportSuccess(null);
            setAddressesImportError(null);
            this.resetState(onClose);
        }
    };

    resetState = (callback = null) => {
        this.setState(
            {
                file: null,
                fileName: null,
                notification: null,
                processingFile: false
            },
            () => {
                if (callback) callback();
            }
        );
    };

    onFileImported = (importedFile) => {
        const fileName = importedFile?.name;
        const reader = new FileReader();

        if (!importedFile) {
            this.resetState();
            return null;
        }

        this.setState({ processingFile: true });
        this.props.setAddressesImportSuccess(null);
        this.props.setAddressesImportError(null);

        const setNotification = ({ title, message, type, icon }) => {
            const notification = {
                title,
                message,
                type,
                icon,
            };
            this.setState({
                file: null,
                fileName: null,
                notification: notification,
                processingFile: false,
            });
        };

        // validate file extension is CSV
        if (!fileName.match(/.+\.csv/i)) {
            setNotification({
                title: 'Unsupported file type',
                message: (
                    <div>
                        Direct Mail Import only supports <strong>CSV</strong> files.
                    </div>
                ),
                type: 'danger',
                icon: 'fas fa-exclamation-circle',
            });
            return;
        }

        reader.readAsText(importedFile, 'UTF-8');

        reader.onload = (e) => {
            // match firt line of csv (excluding the new line)
            let headers = e.target.result.match(/(.*)\r?\n/)[1];

            // format headers
            headers = headers
                .split(',')
                .map((header) => header.replace(/'/g, '').trim());

            const notification = {
                title: 'Missing required column',
                message: (
                    <div>
                        Direct Mail Import only supports <strong>CSV</strong> files.
                    </div>
                ),
                type: 'danger',
                icon: 'fas fa-exclamation-circle',
            };
            if (!headers.includes('ADDRESS')) {
                notification.message = (
                    <div>
                        The CSV file is missing the required <strong>ADDRESS</strong>{' '}
                        column.
                    </div>
                );
                setNotification(notification);
                return;
            }

            if (!headers.includes('ZIP CODE')) {
                notification.message = (
                    <div>
                        The CSV file is missing the required <strong>zip</strong>{' '}
                        column.
                    </div>
                );
                setNotification(notification);
                return;
            }

            let fileSizeNotification = null;
            if (e.total > 1024 * 1024 * 10) {
                // 10MiB approx
                fileSizeNotification = {
                    title: 'File size warning',
                    message: (
                        <div>
                        Due to the size of the file it might take up to{' '}
                        <u>several minutes</u> to complete your upload.
                        <div>
                            Please <strong>do not</strong> close or refresh this page until
                            the upload is completed.
                        </div>
                        Thank you for your patience!
                        </div>
                    ),
                    type: 'warning',
                    icon: 'fas fa-clock',
                };
            }

            this.setState({
                file: importedFile,
                fileName: fileName,
                notification: fileSizeNotification,
                processingFile: false,
            });
        };

        reader.onerror = () => {
            this.resetState();
        };
    };

    onUploadAddresses = () => {
        const { file } = this.state;
        const { 
            uploadAddressesFile,
            iotEndpoint
        } = this.props;

        uploadAddressesFile(file, iotEndpoint);
    };

    onAllBatchesProcessed = () => {
        const {
            setUploadingAddresses,
            setAddressesImportSuccess,
            setAddressesImportError,
        } = this.props;

        setUploadingAddresses(false);
        setAddressesImportSuccess(true);
        setAddressesImportError(false);
        this.resetState();
    };

    onUploadFailed = () => {
        const { 
            setUploadingAddresses
        } = this.props;

        this.resetState(() => {
            setUploadingAddresses(false);
        });
    };

    uploadButtonDisabled = () => {
        const {
            file,
            processingFile,
        } = this.state;

        const { uploadingAddresses } = this.props;
        
        return (
            processingFile ||
            uploadingAddresses ||
            !file
        );
    };

    renderUploadProgress = () => {
        let { 
            uploadingAddresses, 
            uploadFailed,
            uploadProgress
        } = this.props;

        if (uploadFailed) {
            return null;
        }

        if (uploadingAddresses) {
            if(Number(uploadProgress) >= 100) {
                return <Loader loadingMessage='Processing...' />;
            }

            return (
                <div className='d-flex align-items-center w-100'>
                    <span className='mr-2'>
                        <Loader />
                    </span>
                    <div className='flex-grow-1'>
                        <ProgressBar
                            max={100}
                            variant='info'
                            now={uploadProgress}
                            label={`Uploading... ${uploadProgress}%`}
                        />
                    </div>
                </div>
            );
        }

        return null;
    };

    renderNotification = () => {
        const { notification } = this.state;

        if (notification) {
            return (
                <div className='pt-3 pr-3 pl-3 pb-1'>
                    <InlineNotification
                        title={notification.title}
                        message={notification.message}
                        type={notification.type}
                        icon={notification.icon}
                    />
                </div>
            );
        }

        return null;
    };

    renderImportResult = () => {
        const { uploadSuccessful, uploadFailed,notificationtAddressesSuccess } = this.props;
        let  message = notificationtAddressesSuccess?.messageObj ?`Inserted :${notificationtAddressesSuccess?.messageObj?.inserted} Updated :${notificationtAddressesSuccess?.messageObj?.updated} Ignored :${notificationtAddressesSuccess?.messageObj?.ignored}`:'';

        if (uploadSuccessful) {
            return (
                <div className='pt-3 pr-3 pl-3 pb-1'>
                    <InlineNotification
                        title='Addresses have been imported successfully'
                        message={ `${message}`}
                        type='success'
                        icon='fas fa-check-circle'
                    />
                </div>
            );
        }

        if (uploadFailed) {
            return (
                <div className='pt-3 pr-3 pl-3 pb-1'>
                    <InlineNotification
                        title='Import failed'
                        message='Addresses could not be imported. This could be an issue with the selected file or a network related issue.'
                        type='danger'
                        icon='fas fa-exclamation-circle'
                    />
                </div>
            );
        }
    };

    componentDidMount() {
        const {
            setAddressesImportSuccess,
            setAddressesImportError
        } = this.props;
        
        setAddressesImportSuccess(null);
        setAddressesImportError(null);
        this.resetState();
    }

    render() {
        const {
            file,
            processingFile
        } = this.state;

        const { 
            show, 
            uploadingAddresses
        } = this.props;

        return (
            <Modal
                show={show}
                onHide={() => this.onCloseModal()}
                className='import-address-list-modal'
            >
                <Modal.Header>
                    <i
                        onClick={() => this.onCloseModal()}
                        className='far fa-times-circle'
                    ></i>
                    <div className='modal-title'>Import Address List</div>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex flex-column align-items-center w-100 pb-3'>
                        <input
                            type='file'
                            accept='.csv'
                            onChange={(e) => this.onFileImported(e.target.files[0])}
                            className='p-1'
                            style={{
                                border: '1px solid var(--brandPrimaryColor)',
                                borderRadius: '5px',
                                overflow: 'hidden',
                            }}
                            disabled={processingFile || uploadingAddresses}
                        ></input>
                        {processingFile &&
                            <div className='mt-2'>
                                <Loader loadingMessage='Processing file...' />
                            </div>
                        }
                        {(file && !processingFile) ? (
                            <div className='d-flex align-items-center justify-content-center brand-primary-color mt-2'>
                                <i className='fas fa-check-circle mr-2'></i>
                                <span className='font-family-bold'>Ready to upload!</span>
                            </div>
                            ) : null
                        }
                        {this.renderNotification()}
                    </div>
                </Modal.Body>
                <Modal.Footer className='d-flex flex-column align-items-center'>
                    {
                        !uploadingAddresses &&
                            <Button
                                action={() => this.onUploadAddresses()}
                                btnType='primary'
                                label='Upload file'
                                disabled={this.uploadButtonDisabled()}
                                loading={uploadingAddresses}
                            />
                    }
                    {this.renderUploadProgress()}
                    {this.renderImportResult()}
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    uploadingAddresses: state.addressesImport?.uploadingAddresses || false,
    uploadSuccessful: state.addressesImport?.uploadSuccessful || null,
    uploadFailed: state.addressesImport?.uploadFailed || null,
    uploadProgress: state.addressesImport?.uploadProgress || 0,
    notificationtAddressesSuccess: state?.notifications?.UPLOAD_ADDRESSES_SUCCESS
});

export default connect(mapStateToProps, {
    uploadAddressesFile: uploadAddressesFileAction,
    setUploadingAddresses: setUploadingAddressesAction,
    setAddressesImportSuccess: setAddressesImportSuccessAction,
    setAddressesImportError: setAddressesImportErrorAction,
})(ImportAddressesModal);
