import React, { Component } from 'react';
import { connect } from 'react-redux';

import Textarea from 'components/common/textarea/Textarea';

import userSession from 'utils/userSession';

import { addComments as addCommentsAction } from 'actions/mailingPlanActions';
import { setHaveUnsavedChangesAction } from 'actions/statusAccions';

export class PlanComments extends Component {
    state = {
        maxCommentChars: 400
    }

    commentsDisabled = () => {
        const { overrideEnabled } = this.props;

        if(userSession.isDivisionalUser()) {
            return !overrideEnabled ? true : false;
        }

        return false;
    }

    render() {
        const {
            comments,
            addComments,
            commentsRequired
        } = this.props;

        const { maxCommentChars } = this.state;

        return (
            <div>
                <Textarea 
                    className='mailing-plan-comments' 
                    placeholder='Add a comment'
                    maxLength={maxCommentChars}
                    value={comments}
                    onChange={({target: {value}}) => {
                        this.props.setHaveUnsavedChanges(true);
                        addComments(value);
                    }}
                    disabled={this.commentsDisabled()}
                    displayMaxChars={true}
                    required={commentsRequired}
                    errorMsg='Please enter any corrections to your franchise information here'
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    comments: state.mailingPlan?.comments || '',
    overrideEnabled: state.mailingPlan?.overrideEnabled,
    commentsRequired: state.franchise?.commentsRequired
});

export default connect(mapStateToProps, {
    addComments: addCommentsAction,
    setHaveUnsavedChanges: setHaveUnsavedChangesAction
})(PlanComments);
