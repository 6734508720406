import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Button from 'components/common/button/Button';
import { setHaveUnsavedChangesAction } from 'actions/statusAccions';

export class CampaignWizardForm extends Component {
    state = {
        currentStep: 0,
        firstStep: 0,
        lastStep: this.props.formSteps?.size || 0
    }

    static propTypes = {
        campaignName: PropTypes.string.isRequired,
        show: PropTypes.bool.isRequired,
        onHide: PropTypes.func.isRequired,
        onComplete: PropTypes.func.isRequired,
        formSteps: PropTypes.instanceOf(Map).isRequired,
        selectedStepId: PropTypes.string,
        lockNextStep: PropTypes.bool
    }

    renderStepIndicators = () => {
        const { currentStep } = this.state;
        const { formSteps } = this.props;
        const steps = [];

        for(let i = 0; i < formSteps.size; i++) {
            steps.push(i);
        }

        return steps.map(step => {
            return (
                <i key={step} className={`
                    campaign-config-step-indicator 
                    ${currentStep > step ? 'fas fa-circle completed' : ''}
                    ${currentStep === step ? 'far fa-circle active' : ''}
                    ${currentStep < step ? 'fas fa-circle' : ''}
                `}></i>
            );
        });
    }

    onMoveToNext = () => {
        const { setHaveUnsavedChanges  } = this.props;
        setHaveUnsavedChanges(true);
        this.setState((state, props) => { 
            return {
                currentStep: state.currentStep + 1 
            }
        });
    }

    onMoveToPrev = () => {
        this.setState((state, props) => { 
            return {
                currentStep: state.currentStep - 1 
            }
        });
    }

    setCurrentStep = () => {
        let currentIndex = 0;

        if(this.props.selectedStepId) {
            this.props.formSteps?.forEach((step, index) => {
                if(step.id === this.props.selectedStepId) {
                    currentIndex = index;
                }
            });
        }

        return currentIndex;
    }

    componentDidMount() {
        if(this.props.selectedStepId) {
            this.setState({ currentStep: this.setCurrentStep() });
        }
    }

    render() {
        const {
            currentStep,
            firstStep,
            lastStep
        } = this.state;

        const { 
            campaignName,
            formSteps,
            show,
            onHide,
            onComplete,
            lockNextStep,
            setHaveUnsavedChanges
        } = this.props

        return (
            <Modal show={show} onHide={onHide} className='campaign-config-wizard'>
                <Modal.Header>
                    <div className='campaign-config-modal-subtitle'>{campaignName}</div>
                    <div className='campaign-config-modal-title'>{formSteps.get(currentStep)?.title}</div>
                    <i onClick={() => onHide()} className='far fa-times-circle campaign-config-close-btn'></i>
                </Modal.Header>
                <Modal.Body>
                    <div className='campaign-config-wizard-steps mb-4'>
                        {this.renderStepIndicators()}
                    </div>
                    <div className='campaign-config-step-content'>
                        {formSteps.get(currentStep)?.content}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        currentStep > firstStep &&
                            <Button 
                                btnType='outline-primary' 
                                label='Back'
                                action={() => this.onMoveToPrev()}
                            />
                    }
                    {
                        currentStep < lastStep-1 &&
                            <Button 
                                btnType='primary' 
                                label='Next' 
                                disabled={lockNextStep}
                                action={() => this.onMoveToNext()}
                            />
                    }
                    {
                        currentStep === lastStep-1 &&
                            <Button 
                                btnType='primary' 
                                label='Complete Campaign' 
                                disabled={lockNextStep}
                                action={() =>{ setHaveUnsavedChanges(true); onComplete()}}
                            />
                    }
                </Modal.Footer>
            </Modal>
        );
    }
}

export default connect(null, {
    setHaveUnsavedChanges: setHaveUnsavedChangesAction
})(CampaignWizardForm);