import {
    SUBMIT_MAILING_PLAN_ERROR,
    GET_ZIP_CODES_ERROR,
    GET_MAILING_PLAN_ERROR,
    GET_FRANCHISE_INFO_ERROR,
    GET_BRAND_INFO_ERROR,
    FRANCHISE_INFO_CONFIRM_WARNING,
    CLOSE_NOTIFICATION,
    GET_MAILING_PLANS_ERROR,
    GET_MAILING_PLAN_STATUSES_ERROR,
    APPROVE_MAILING_PLAN_ERROR,
    GET_FRANCHISES_FILTER_DATA_ERROR,
    GET_MAILING_PLANS_OVERVIEW_ERROR,
    REJECT_MAILING_PLAN_ERROR,
    FRANCONNECT_SYNC_ERROR,
    FRANCONNECT_SYNC_SUCCESS,
    INSUFFICIENT_ADDRESSES_WARNING,
    OVERRIDE_MAILING_PLAN_ERROR,
    GET_MAILING_YEARS_ERROR,
    GET_ADDRESS_IMPORTS_LOG_ERROR,
    GET_CAMPAIGNS_TEMPLATES_ERROR,
    GET_YEARLY_PLANS_ERROR,
    GET_BUSINESS_RULES_ERROR,
    DELETE_ADDRESSES_FILE_ERROR,
    DELETE_ADDRESSES_FILE_SUCCESS,
    EXPORT_CAMPAIGN_ERROR,
    EXPORT_CAMPAIGN_SUCCESS,
    GET_ACTIVE_PLAN_CAMPAIGN_TEMPLATES_ERROR,
    POST_NEW_CAMPAIGN_TEMPLATES_ERROR,
    UPDATE_CAMPAIGN_TEMPLATES_ERROR,
    ACTIVATE_MAILING_PLAN_TEMPLATE_ERROR,
    UPDATE_MAILING_PLAN_TEMPLATE_ERROR,
    GET_MAILING_PLAN_TEMPLATE_ERROR,
    DOWNLOAD_CAMPAIGN_EXPORT_ERROR,
    ACTIVATE_MAILING_PLAN_TEMPLATE_SUCCESS,
    UPDATE_MAILING_PLAN_TEMPLATE_SUCCESS,
    DELETE_CAMPAIGNS_FROM_TEMPLATE_ERROR,
    GET_SEND_ORDER_OPTIONS_ERROR,
    DELETE_MAILING_PLAN_TEMPLATE_ERROR,
    DELETE_MAILING_PLAN_TEMPLATE_SUCCESS,
    GET_MAILING_PLAN_SUBMISSIONS_ERROR,
    GET_COUPONS_ERROR,
    GET_BROCHURES_ERROR,
    SAVE_BROCHURE_ERROR,
    SAVE_BROCHURE_SUCCESS,
    UPDATE_BROCHURE_ERROR,
    UPDATE_BROCHURE_SUCCESS,
    DELETE_BROCHURE_ERROR,
    DELETE_BROCHURE_SUCCESS,
    SAVE_BROCHURE_IMAGE_ERROR,
    GET_BROCHURE_COUPONS_ERROR,
    GET_BROCHURES_FOR_CONFIG_LIST_ERROR,
    GET_COUPONS_FOR_CONFIG_LIST_ERROR,
    SAVE_COUPON_ERROR,
    SAVE_COUPON_SUCCESS,
    UPDATE_COUPON_ERROR,
    UPDATE_COUPON_SUCCESS,
    DELETE_COUPON_ERROR,
    DELETE_COUPON_SUCCESS,
    CREATE_YEARLY_PLAN_ERROR,
    CREATE_YEARLY_PLAN_SUCCESS,
    GET_COUPON_DISCOUNTS_ERROR,
    GET_DISCOUNTS_ERROR,
    SAVE_DISCOUNT_ERROR,
    SAVE_DISCOUNT_SUCCESS,
    UPDATE_DISCOUNT_ERROR,
    UPDATE_DISCOUNT_SUCCESS,
    DELETE_DISCOUNT_ERROR,
    DELETE_DISCOUNT_SUCCESS,
    GET_DISCOUNTS_FOR_CONFIG_LIST_ERROR,
    GET_SERVICES_FOR_CONFIG_LIST_ERROR,
    SAVE_SERVICE_ERROR,
    SAVE_SERVICE_SUCCESS,
    UPDATE_SERVICE_ERROR,
    UPDATE_SERVICE_SUCCESS,
    DELETE_SERVICE_ERROR,
    DELETE_SERVICE_SUCCESS,
    GET_CAMPAIGNS_REPORT_ERROR,
    DOWNLOAD_ADDRESSES_FILE_ERROR,
    CONFIRM_EMAIL_REMINDERS_ERROR,
    CONFIRM_EMAIL_REMINDERS_SUCCESS,
    GENERIC_NOTIFICATION,
    SHOW_NOTIFICATION,
    DOWNLOAD_PLAN_EXPORT_ERROR,
} from './types';

export const closeNotification = id => ({
    type: CLOSE_NOTIFICATION,
    payload: { id }
});

export const showNotification = ({
    id = GENERIC_NOTIFICATION,
    title = 'Network Error',
    message = '',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: SHOW_NOTIFICATION,
        payload: { 
            id,
            title,
            message,
            type,
            serverError,
            display 
        }
    };
};

export const notifyGetBrandInfoError = ({
    title = 'Network Error',
    message = 'Could not load brand information',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: GET_BRAND_INFO_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyGetMailingPlanError = ({
    title = 'Network Error',
    message = 'Could not load mailing plan',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: GET_MAILING_PLAN_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyMailingPlanSubmitError = ({
    title = 'Network Error',
    message = 'Error submitting mailing plan',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: SUBMIT_MAILING_PLAN_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyGetFranchiseInfoError = ({
    title = 'Network Error',
    message = 'Could not load franchise information',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: GET_FRANCHISE_INFO_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyGetBrochuresError = ({
    title = 'Network Error',
    message = 'Could not load brochures',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: GET_BROCHURES_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyGetCouponsError = ({
    title = 'Network Error',
    message = 'Could not load coupons',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: GET_COUPONS_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyGetDiscountsError = ({
    title = 'Network Error',
    message = 'Could not load discounts',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: GET_DISCOUNTS_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyGetZipCodeError = ({
    title = 'Network Error',
    message = 'Could not load postal codes',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: GET_ZIP_CODES_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyFranchiseInfoConfirmation = ({
    title = 'Confirm franchise info',
    message = 'Please confirm franchise info is correct',
    type = 'warning',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: FRANCHISE_INFO_CONFIRM_WARNING,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyGetMailingPlansError = ({
    title = 'Network Error',
    message = 'Error fetching mailing plans',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: GET_MAILING_PLANS_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyGetMailingPlanStatusesError = ({
    title = 'Filter Unavailable: Status',
    message = 'Error fetching mailing plan statuses. Filtering by status will be unavailable.',
    type = 'warning',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: GET_MAILING_PLAN_STATUSES_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyGetFranchisesError = ({
    title = 'Filter Unavailable: Franchise',
    message = 'Error fetching mailing plan franchises. Filtering by franchise will be unavailable.',
    type = 'warning',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: GET_FRANCHISES_FILTER_DATA_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyMailingPlanApproveError = ({
    title = 'Network Error',
    message = 'Error approving mailing plan',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: APPROVE_MAILING_PLAN_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyMailingPlanRejectError = ({
    title = 'Network Error',
    message = 'Error rejecting mailing plan',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: REJECT_MAILING_PLAN_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyGetMailingPlansOverviewError = ({
    title = 'Network Error',
    message = 'Error fetching mailing plans overview',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: GET_MAILING_PLANS_OVERVIEW_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyFranconnectSyncError = ({
    title = 'Network Error',
    message = 'FranConnect synchronization failed',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: FRANCONNECT_SYNC_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyFranconnectSyncSuccess = ({
    title = 'Successful Sync',
    message = 'FranConnect synchronization complete',
    type = 'success',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: FRANCONNECT_SYNC_SUCCESS,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyInsufficientAddresses = ({
    customtitle = '',
    customMessage = '',
    customType = '',
    customConfigObj = {},
    customConfigKey = INSUFFICIENT_ADDRESSES_WARNING,
    display = true,
} = {}) => {
    let title =
        customtitle !== null ? customtitle || 'Insufficient addresses' : null;
    let message =
        customMessage !== null
            ? customMessage ||
              `
        The selected postal codes do not contain enough addresses for all your campaigns. 
        Please select additional postal codes or reduce the number of addresses for the following campaigns:
    `
            : null;
    let type = customType !== null ? customType || 'warning' : null;

    return {
        type: INSUFFICIENT_ADDRESSES_WARNING,
        payload: {
            title,
            message,
            type,
            customConfigObj,
            customConfigKey,
            display,
        },
    };
};

export const notifyMailingPlanOverrideError = ({
    title = 'Network Error',
    message = 'Error overriding mailing plan',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: OVERRIDE_MAILING_PLAN_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyGetMailingYearsError = ({
    title = 'Filter Unavailable: Year',
    message = 'Error getting mailing years. Filtering by mailing year will be unavailable. Only plans for the current year will be displayed.',
    type = 'warning',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: GET_MAILING_YEARS_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyGetAddressImportsLogError = ({
    title = 'Network Error',
    message = 'Error getting address imports log',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: GET_ADDRESS_IMPORTS_LOG_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyGetCampaignsTemplatesError = ({
    title = 'Filter Unavailable: Campaign Date',
    message = 'Error getting campaign dates. Filtering by campaign date will be unavailable.',
    type = 'warning',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: GET_CAMPAIGNS_TEMPLATES_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyGetYearlyPlansError = ({
    title = 'Network Error',
    message = 'Error getting yearly plans',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: GET_YEARLY_PLANS_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyGetBusinessRulesError = ({
    title = 'Network Error',
    message = 'Error getting business rules',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: GET_BUSINESS_RULES_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyDeleteAddressesFileError = ({
    title = 'Network Error',
    message = 'Unable to delete address list',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: DELETE_ADDRESSES_FILE_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyDeleteAddressesFileSuccess = ({
    customtitle = '',
    customMessage = '',
    customType = '',
    customConfigObj = {},
    customConfigKey = DELETE_ADDRESSES_FILE_SUCCESS,
    display = true,
} = {}) => {
    let title = customtitle !== null ? customtitle || 'Successful delete' : null;
    let message =
        customMessage !== null
            ? customMessage || 'Addresses have been deleted'
            : null;
    let type = customType !== null ? customType || 'success' : null;

    return {
        type: DELETE_ADDRESSES_FILE_SUCCESS,
        payload: {
            title,
            message,
            type,
            customConfigObj,
            customConfigKey,
            display,
        },
    };
};

export const notifyExportCampaignError = ({
    title = 'Network Error',
    message = 'Error generating campaign export',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: EXPORT_CAMPAIGN_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyExportCampaignSuccess = ({
    title = 'Export Successful',
    message = `Campaign has been successfully exported and is now available for download`,
    type = 'success',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: EXPORT_CAMPAIGN_SUCCESS,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyGetMailingPlanTemplateError = ({
    title = 'Network Error',
    message = 'Error fetching mailing plan template',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: GET_MAILING_PLAN_TEMPLATE_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyGetActiveCampaignTemplatesError = ({
    title = 'Network Error',
    message = 'Error fetching campaigns',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: GET_ACTIVE_PLAN_CAMPAIGN_TEMPLATES_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifySaveNewCampaignTemplatesError = ({
    title = 'Network Error',
    message = 'Could not save campaign templates',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: POST_NEW_CAMPAIGN_TEMPLATES_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyUpdateMailingPlanTemplateError = ({
    title = 'Network Error',
    message = 'Failed to update mailing plan',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: UPDATE_MAILING_PLAN_TEMPLATE_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyUpdateCampaignTemplatesError = ({
    title = 'Network Error',
    message = 'Could not update campaign templates',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: UPDATE_CAMPAIGN_TEMPLATES_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyActivateMailingPlanError = ({
    title = 'Network Error',
    message = 'Failed to activate mailing plan',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: ACTIVATE_MAILING_PLAN_TEMPLATE_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyActivateMailingPlanSuccess = ({
    title = 'Plan activated successfully',
    message = 'Mailing plan has been activated and is now available for Franchise Owners',
    type = 'success',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: ACTIVATE_MAILING_PLAN_TEMPLATE_SUCCESS,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyUpdateMailingPlanSuccess = ({
    title = 'Changes saved',
    message = 'Mailing plan has been updated successfully',
    type = 'success',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: UPDATE_MAILING_PLAN_TEMPLATE_SUCCESS,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyDeleteCampaignsFromTemplateError = ({
    title = 'Network Error',
    message = 'Could not delete campaigns from mailing plan',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: DELETE_CAMPAIGNS_FROM_TEMPLATE_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyDownloadCampaignExportError = ({
    title = 'Network Error',
    message = 'Could not retrieve campaign export information',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: DOWNLOAD_CAMPAIGN_EXPORT_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyDownloadPlanExportError = ({
    title = 'Network Error',
    message = 'Could not retrieve plan export information',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: DOWNLOAD_PLAN_EXPORT_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyGetSendOrderOptionsError = ({
    title = 'Network Error',
    message = 'Could not retrieve send order options',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: GET_SEND_ORDER_OPTIONS_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyDeleteMailingPlanTemplateError = ({
    title = 'Network Error',
    message = 'Failed to delete mailing plan template',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: DELETE_MAILING_PLAN_TEMPLATE_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyDeleteMailingPlanTemplateSuccess = ({
    title = 'Mailing plan deleted',
    message = 'Mailing plan has been successfully deleted from your list',
    type = 'success',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: DELETE_MAILING_PLAN_TEMPLATE_SUCCESS,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyGetMailingPlanSubmissionsError = ({
    title = 'Network Error',
    message = 'Could not fetch mailing plan submissions',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: GET_MAILING_PLAN_SUBMISSIONS_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifySaveBrochureError = ({
    title = 'Network Error',
    message = 'Could not save new brochure',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: SAVE_BROCHURE_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyUpdateBrochureError = ({
    title = 'Network Error',
    message = 'Could not save updated brochure',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: UPDATE_BROCHURE_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifySaveBrochureImageError = ({
    title = 'Network Error',
    message = 'Error saving brochure image',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: SAVE_BROCHURE_IMAGE_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyGetBrochureCouponsError = ({
    title = 'Network Error',
    message = 'Error fetching brochure coupons',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: GET_BROCHURE_COUPONS_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyGetBrochuresForConfigError = ({
    title = 'Network Error',
    message = 'Error fetching brochures',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: GET_BROCHURES_FOR_CONFIG_LIST_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyDeleteBrochureError = ({
    title = 'Network Error',
    message = 'Error deleting brochure',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: DELETE_BROCHURE_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifySaveBrochureSuccess = ({
    title = 'Brochure saved',
    message = 'New brochure has been successfully created',
    type = 'success',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: SAVE_BROCHURE_SUCCESS,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyUpdateBrochureSuccess = ({
    title = 'Brochure updated',
    message = 'Brochure has been successfully updated',
    type = 'success',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: UPDATE_BROCHURE_SUCCESS,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyDeleteBrochureSuccess = ({
    title = 'Brochure deleted',
    message = 'Brochure has been successfully removed',
    type = 'success',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: DELETE_BROCHURE_SUCCESS,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyGetCouponsForConfigListError = ({
    title = 'Network Error',
    message = 'Error fetching coupons',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: GET_COUPONS_FOR_CONFIG_LIST_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifySaveCouponError = ({
    title = 'Network Error',
    message = 'Could not save new coupon',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: SAVE_COUPON_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifySaveCouponSuccess = ({
    title = 'Coupon saved',
    message = 'New coupon has been successfully created',
    type = 'success',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: SAVE_COUPON_SUCCESS,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyUpdateCouponError = ({
    title = 'Network Error',
    message = 'Could not update coupon',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: UPDATE_COUPON_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyUpdateCouponSuccess = ({
    title = 'Coupon updated',
    message = 'Coupon has been successfully updated',
    type = 'success',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: UPDATE_COUPON_SUCCESS,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyDeleteCouponError = ({
    title = 'Network Error',
    message = 'Could not delete coupon',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: DELETE_COUPON_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyDeleteCouponSuccess = ({
    title = 'Coupon deleted',
    message = 'Coupon has been successfully removed',
    type = 'success',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: DELETE_COUPON_SUCCESS,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyGetCouponDiscountsError = ({
    title = 'Network Error',
    message = "Could not load coupon's discounts",
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: GET_COUPON_DISCOUNTS_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyGetDiscountsForConfigListError = ({
    title = 'Network Error',
    message = 'Error fetching discounts',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: GET_DISCOUNTS_FOR_CONFIG_LIST_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifySaveDiscountError = ({
    title = 'Network Error',
    message = 'Could not save new discount',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: SAVE_DISCOUNT_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifySaveDiscountSuccess = ({
    title = 'Discount saved',
    message = 'New discount has been successfully created',
    type = 'success',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: SAVE_DISCOUNT_SUCCESS,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyUpdateDiscountError = ({
    title = 'Network Error',
    message = 'Could not update discount',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: UPDATE_DISCOUNT_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyUpdateDiscountSuccess = ({
    title = 'Discount updated',
    message = 'Discount has been successfully updated',
    type = 'success',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: UPDATE_DISCOUNT_SUCCESS,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyDeleteDiscountError = ({
    title = 'Network Error',
    message = 'Could not delete discount',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: DELETE_DISCOUNT_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyDeleteDiscountSuccess = ({
    title = 'Discount deleted',
    message = 'Discount has been successfully removed',
    type = 'success',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: DELETE_DISCOUNT_SUCCESS,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyGetServicesForConfigListError = ({
    title = 'Network Error',
    message = 'Error fetching services',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: GET_SERVICES_FOR_CONFIG_LIST_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifySaveServiceError = ({
    title = 'Network Error',
    message = 'Could not save new service',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: SAVE_SERVICE_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifySaveServiceSuccess = ({
    title = 'Service saved',
    message = 'New service has been successfully created',
    type = 'success',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: SAVE_SERVICE_SUCCESS,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyUpdateServiceError = ({
    title = 'Network Error',
    message = 'Could not update service',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: UPDATE_SERVICE_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyUpdateServiceSuccess = ({
    title = 'Service updated',
    message = 'Service has been successfully created',
    type = 'success',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: UPDATE_SERVICE_SUCCESS,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyDeleteServiceError = ({
    title = 'Network Error',
    message = 'Could not delete service',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: DELETE_SERVICE_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyDeleteServiceSuccess = ({
    title = 'Service deleted',
    message = 'Service has been successfully removed',
    type = 'success',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: DELETE_SERVICE_SUCCESS,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyCreateYearlyPlanError = ({
    title = 'Network Error',
    message = 'Could not create new yearly plan',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: CREATE_YEARLY_PLAN_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyCreateYearlyPlanSuccess = ({
    title = 'New plan created!',
    message = 'New plan has been successfully created',
    type = 'success',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: CREATE_YEARLY_PLAN_SUCCESS,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyGetCampaignsReportError = ({
    title = 'Network Error',
    message = 'Error fetching campaigns report',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: GET_CAMPAIGNS_REPORT_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyDownloadAddressesFileError = (
    title = 'Network Error',
    message = 'Imported address file not found',
    type = 'danger',
    display = true,
    serverError = null,
) => {
    return {
        type: DOWNLOAD_ADDRESSES_FILE_ERROR,
        payload: { title, message, type, serverError, display },
    };
};

export const notifyCampaignRemindersError = (
    title = 'Server Error',
    message = 'Error while trying to start send email process',
    type = 'danger'
) => {
    return {
        type: CONFIRM_EMAIL_REMINDERS_ERROR,
        payload: { title, message, type },
    };
};

export const notifyCampaignRemindersTriggerSuccess = ({
    title = 'Successful Trigger For Campaign Reminders',
    message = 'Email Reminders Triggered',
    type = 'success',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: CONFIRM_EMAIL_REMINDERS_SUCCESS,
        payload: { title, message, type, serverError, display },
    };
};

export const notifySyncSuccess = ({
    title = 'Successful',
    message = 'Sync Successful',
    type = 'success',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: SHOW_NOTIFICATION,
        payload: { title, message, type, serverError, display },
    };
};
export const notifyUpdateLocakDateReminderError = ({
    title = 'Network Error',
    message = 'Could not update lock date reminder',
    type = 'danger',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: SHOW_NOTIFICATION,
        payload: { title, message, type, serverError, display },
    };
};
export const notifyUpdateLocakDateReminderSuccess = ({
    title = 'Successful',
    message = 'Lock Date Reminder toggle has been updated successfully',
    type = 'success',
    serverError = null,
    display = true,
} = {}) => {
    return {
        type: SHOW_NOTIFICATION,
        payload: { title, message, type, serverError, display },
    };
};