import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import formatAddress from 'utils/formatAddress';
import FranchiseInfoConfirmation from './FranchiseInfoConfirmation';
import Input from 'components/common/input/Input';
import Switch from 'components/common/switch/Switch';
import userSession from 'utils/userSession';
import { changeLicenseInputs } from 'actions/franchiseActions';
import validateStatus from 'utils/validateStatus';
import { setHaveUnsavedChangesAction } from 'actions/statusAccions';

export class FranchiseInfo extends Component {
    state = {
        licenseNumber: '',
        overrideLicense: false,
        hideLicenseInputs: false
    }
   
    componentWillReceiveProps(props) {
        const {
            franchise,
            mailingPlan,
        } = this.props;
        this.setState({ hideLicenseInputs: (mailingPlan?.status == undefined || mailingPlan?.status == "Saved" || validateStatus.isDraft(mailingPlan?.status)) && userSession.isMSQUser()?false:true });
     }

    onChangeOverrideLicense = (overrideValue) => {
        const {
            franchise,
        } = this.props;
        const {
            licenseNumber,
            overrideLicense
        } = this.state;
        this.props.setHaveUnsavedChanges(true);
        this.setState({ overrideLicense: overrideValue }, () => {
           this.props.changeLicenseInputs(overrideValue, licenseNumber,"override");
        });
        if(!overrideValue)  {
            this.props.changeLicenseInputs(overrideLicense, franchise?.licenseNumber ,"license");
        }  
    }
    
    onChangeLicense = () => {
        const {
            licenseNumber,
            overrideLicense
        } = this.state;
        this.props.setHaveUnsavedChanges(true);
        this.props.changeLicenseInputs(overrideLicense, licenseNumber,"license");
    }
    render() {
        const {
            mailingPlan,
            franchise
        } = this.props;
        const {
            hideLicenseInputs
        } = this.state;
        const marketingUrlLabel = userSession.isMTUser()? "Location URL":"Marketing URL";
        return (
            <Fragment>
                {
                    !franchise?.franchiseId ?
                        <div>Franchise information is not available</div>
                    :
                        <Fragment>
                            <div className='franchise-info-container border-bottom'>
                                <div className='info-group'>
                                    <small className='info-label'>Return Address</small>
                                    <div className='info-value'>
                                        {
                                            formatAddress(
                                                franchise?.address1,
                                                franchise?.city,
                                                franchise?.state,
                                                franchise?.zipCode
                                            ) || '-'
                                        }
                                    </div>
                                </div>
                                <div className='d-flex column-gap-2'>
                                    <div className='info-group'>
                                        <small className='info-label'>Direct Mail Tracking Number</small>
                                        <div className='info-value'>{franchise?.phone || '-'}</div>
                                    </div>
                                    {/* <div className='info-group'>
                                        <small className='info-label'>Receiving Number</small>
                                        <div className='info-value'>{franchise?.receivingNumber || '-'}</div>
                                    </div> */}
                                </div>
                                <div className='info-group'>
                                    <small className='info-label'>{marketingUrlLabel}</small>
                                    <div className='info-value'>{franchise?.url || '-'}</div>
                                </div>
                                <div className={`info-group ${hideLicenseInputs?'':'hidden'}`}>                                    <small className='info-label'>License Number</small>
                                    <div className='info-value'>{mailingPlan?.licenseNumber || '-'}</div>
                                </div>
                                <div div className={`info-group ${hideLicenseInputs?'hidden':''}`}>
                                    <div class="row ">
                                        <div class="col-8">
                                        <Input 
                                            type='text' 
                                            label='License Number'
                                            placeholder='Enter License Number'
                                            value={mailingPlan?.licenseNumber}
                                            containerStyling='w-100'
                                            onChange={(e) => {
                                                this.setState({ 
                                                    licenseNumber: e.target.value
                                                }, () => {
                                                    this.onChangeLicense();
                                                });
                                            }}
                                            readOnly={!mailingPlan?.overrideLicense}
                                        />
                                        </div>
                                        <div class="col-4">
                                            <Switch
                                                id={`override-license-id`}
                                                label={"Override License"}
                                                labelTop={true}
                                                checked={mailingPlan?.overrideLicense}
                                                onToggle={(checked) => this.onChangeOverrideLicense(checked)} 
                                                />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='info-group'>
                                    <small className='info-label'>Email</small>
                                    <div className='info-value'>{franchise?.email || '-'}</div>
                                </div> */}
                            </div>
                            {
                                (franchise?.franchiseId && mailingPlan?.mailingPlanId) &&
                                    <FranchiseInfoConfirmation />
                            }
                        </Fragment>
                }
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    mailingPlan: state.mailingPlan,
    franchise: state.franchise,
    licenseNumber: state.mailingPlan?.licenseNumber,
    overrideLicense:  state.mailingPlan?.overrideLicense,
});

export default connect(mapStateToProps, {
    changeLicenseInputs,
    setHaveUnsavedChanges: setHaveUnsavedChangesAction
})(FranchiseInfo);