import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Tactic from 'components/lmpbp/budget-plan/tactics/tactic/Tactic';
import Loader from 'components/common/loader/Loader';
import formatCurrency from 'utils/formatCurrency';
import userSession from 'utils/userSession';
import { validateMonthlyBudgets, validateConsecutiveMonthsTactics, validateAnnualBudgetsExeptDiscretionaryMarketingSpendTactic} from 'utils/validateLmpbpPreffredTacticBudgets';

import { LMPBP_GET_TACTICS_ERROR } from 'actions/types';

import { BudgetPlanContext } from '../BudgetPlan';

import { getBudgetPlanTactics } from 'api/lmpbp/budgetPlans';

import {
    closeNotification,
    showNotification,
} from 'actions/notificationActions';
import { brandSpecificInstructions, tacticCommonInstructions } from './TacticInstructions';
import AddCustomTacticForm from './tactic/AddCustomTacticForm';
import CustomTactic from './tactic/CustomTactic';
import validateStatus from 'utils/validateStatus';
import getBrandSymbolFromId from 'utils/getBrandSymbolFromId';
import { enrichTacticWithBrandSettings } from './BrandSpecificConfiguration';

const Tactics = ({ templateId, updatedBudgetEntries, onTacticsLoaded, validationErrors,isResetEntry }) => {
    const dispatch = useDispatch();

    const websiteNeededTacticts = ["SEO", "Scorpion", "PulseM"];
    const { budgetPlan ,template, addingCustomTactic, setAddingCustomTactic } = useContext(BudgetPlanContext);

    const [budgetTotals, setBudgetTotals] = useState({});
    // Note: commented code by stakeholder request on date 2023-12-20
    // const [minAnnualBudgetPrefferedTcticErr, setMinAnnualBudgetPrefferedTcticErr] = useState(false);
    const [minMonthlyBudgetScorpionErr, setMinMonthlyBudgetScorpionErr] = useState(false);
    const [minMonthlyBudgetTDmErr, setMinMonthlyBudgetTDmErr] = useState(false);
    const [budgetConsecutiveMonthsNativeAdvertisingTacticsErr, setBudgetConsecutiveMonthsNativeAdvertisingTacticsErr] = useState(false);
    const [budgetConsecutiveMonthsYoutubeAdvertisingTacticsErr, setBudgetConsecutiveMonthsYoutubeAdvertisingTacticsErr] = useState(false);
    const [budgetConsecutiveMonthsMirrorDisplayTacticsErr, setBudgetConsecutiveMonthsMirrorDisplayTacticsErr] = useState(false);
    const [minAnnualBudgetExeptDiscretionaryMarketingSpendErr, setMinAnnualBudgetExeptDiscretionaryMarketingSpendErr] = useState(false);
    const [minMonthlyBudgetNativeAdvertisingErr, setMinMonthlyBudgetNativeAdvertisingErr] = useState(false);
    const [minMonthlyBudgetMirroredDisplayErr, setMinMonthlyBudgetMirroredDisplayErr] = useState(false);
    const [minMonthlyBudgetyoutubeAdvertisingErr, setMinMonthlyBudgetyoutubeAdvertisingErr] = useState(false);
    const [minMonthlyBudgetMultiviewErr, setMinMonthlyBudgetMultiviewErr] = useState(false);

    const tactics = useQuery(
        ['tactics', budgetPlan?.id],
        () => getBudgetPlanTactics(budgetPlan?.id),
        {
            enabled: !!budgetPlan,
            onError: err => {
                dispatch(
                    showNotification({
                        id: LMPBP_GET_TACTICS_ERROR,
                        message: 'Error fetching budget plan tactics',
                        type: 'danger',
                        serverError: err,
                    })
                );
            },
            onSuccess: (data) => {
                dispatch(closeNotification(LMPBP_GET_TACTICS_ERROR));
                onTacticsLoaded(data);
            },
        }
    );

    useEffect(() => {
        tactics.refetch();
    }, [budgetPlan]);

    const renderTactics = () => {
        const brandSymbol = getBrandSymbolFromId(userSession.getBrandId());
        const brandInstructions = brandSpecificInstructions[brandSymbol];
        return tactics.data?.map((tactic, index) => {
            const TacticComponent = tactic.isFranchiseCustom ? CustomTactic : Tactic;
            const enrichedTacticWithBrandSettings = enrichTacticWithBrandSettings(brandSymbol, tactic);
            return (
                <div
                    key={`${tactic.id}-${index}`}
                    id={`tactic-${index}`}
                    className='mb-3'
                > <TacticComponent
                        tactic={enrichedTacticWithBrandSettings}
                        instructions={tacticCommonInstructions(tactic.name, template, brandInstructions)}
                        isResetEntry={isResetEntry}
                    />
                </div>
            )
        });
    };

    const renderTotal = () => {
        return (
            <div className='position-relative mx-4' id="tactics-total">
                <div className='tactics-total-label'>Total</div>
                <table className='tactics-total-table' id="tactics-total-table">
                    <thead>
                        <tr>
                            <td>${formatCurrency(budgetTotals[0])}</td>
                            <td>${formatCurrency(budgetTotals[1])}</td>
                            <td>${formatCurrency(budgetTotals[2])}</td>
                            <td>${formatCurrency(budgetTotals[3])}</td>
                            <td>${formatCurrency(budgetTotals[4])}</td>
                            <td>${formatCurrency(budgetTotals[5])}</td>
                            <td>${formatCurrency(budgetTotals[6])}</td>
                            <td>${formatCurrency(budgetTotals[7])}</td>
                            <td>${formatCurrency(budgetTotals[8])}</td>
                            <td>${formatCurrency(budgetTotals[9])}</td>
                            <td>${formatCurrency(budgetTotals[10])}</td>
                            <td>${formatCurrency(budgetTotals[11])}</td>
                            <td>
                                $
                                {formatCurrency(
                                    _.sum(Object.values(budgetTotals))
                                )}
                            </td>
                        </tr>
                    </thead>
                </table>
            </div>
        );
    };
    
    useEffect(() => {
        let totals = [];
        const filteredBudgetsEntries = updatedBudgetEntries.filter((item) => item.budgetPlanId == budgetPlan.id);
        if (filteredBudgetsEntries.length) {
            filteredBudgetsEntries.map(({ budgets }) => budgets)
            .forEach(budgets => {
                if(budgets)
                {
                    for (let i = 0; i < 12; i++) {
                        totals[i] =
                            Number(totals[i] || 0) +
                            Number(budgets[i]?.value || 0);
                    }
                }
            });
        }
        setBudgetTotals({...totals});

        // It is necessary to reset error states to false so errors from one plan are not carried
        // to another plan
        if (validateStatus.isApproved(budgetPlan.status) 
        || validateStatus.isSubmitted(budgetPlan.status)) {
            // Note: commented code by stakeholder request on date 2023-12-20
            // setMinAnnualBudgetPrefferedTcticErr(false);
            setMinMonthlyBudgetScorpionErr(false);
            setMinMonthlyBudgetTDmErr(false);
            setBudgetConsecutiveMonthsNativeAdvertisingTacticsErr(false);
            setBudgetConsecutiveMonthsYoutubeAdvertisingTacticsErr(false);
            setBudgetConsecutiveMonthsMirrorDisplayTacticsErr(false);
            setMinAnnualBudgetExeptDiscretionaryMarketingSpendErr(false);
            setMinMonthlyBudgetNativeAdvertisingErr(false);
            setMinMonthlyBudgetMirroredDisplayErr(false);
            setMinMonthlyBudgetyoutubeAdvertisingErr(false);
            setMinMonthlyBudgetMultiviewErr(false);
            return;
        }

        // Note: commented code by stakeholder request on date 2023-12-20
        //DCS Code to validate tacticsMinimumAnnualSpend (annualMinSpendAmountSeoS1DmPulsm)
        // if (requiredAnnualBudgetPrefferedTactics && userSession.isFranchiseOwer() && userSession.isDcsLmpbpUser()) {            
        //     setMinAnnualBudgetPrefferedTcticErr(validatePrefferedTacticsAnnualBudgets(budgetPlan,filteredBudgetsEntries));
        // }

        const requiredAnnualBudgetPrefferedTactics = budgetPlan.tacticsMinimumAnnualSpend;
        //DCS Code to validate tacticsMinimumAnnualSpend for DiscretionaryMarketingSpendTactic
        if (requiredAnnualBudgetPrefferedTactics && userSession.isFranchiseOwer() && userSession.isDcsLmpbpUser()) {            
            setMinAnnualBudgetExeptDiscretionaryMarketingSpendErr(validateAnnualBudgetsExeptDiscretionaryMarketingSpendTactic(budgetPlan,filteredBudgetsEntries));
        }

        if(userSession.isFranchiseOwer() && userSession.isDcsLmpbpUser()){

            //DCS Code to validate scorpionMinMonthlySpendAmount, targatedDmMinMonthlySpendAmount, nativeAdvertisingMinMonthlySpendAmount, youtubeAdvertisingMinMonthlySpendAmount, mirroredDisplayMinMonthlySpendAmount, multiviewMinMonthlySpendAmount 
            let monthlyErrors = validateMonthlyBudgets(budgetPlan,updatedBudgetEntries);

            // setMinMonthlyBudgetScorpionErr(monthlyErrors?.find(err => err.scorpion_err));
            // setMinMonthlyBudgetTDmErr(monthlyErrors?.find(err => err.tdm_err));
            setMinMonthlyBudgetNativeAdvertisingErr(monthlyErrors?.find(err => err.native_adv_err));
            setMinMonthlyBudgetyoutubeAdvertisingErr(monthlyErrors?.find(err => err.youtube_adv_err));
            setMinMonthlyBudgetMirroredDisplayErr(monthlyErrors?.find(err => err.mirror_display_err));
            setMinMonthlyBudgetMultiviewErr(monthlyErrors?.find(err => err.multiview_err));

            //DCS Code to validate budgetConsecutiveMonthsTactics 
            let consecutiveErrors = validateConsecutiveMonthsTactics(updatedBudgetEntries);     
            setBudgetConsecutiveMonthsNativeAdvertisingTacticsErr(consecutiveErrors?.find(err => err.nativeAdvertisingConsecutive));
            setBudgetConsecutiveMonthsYoutubeAdvertisingTacticsErr(consecutiveErrors?.find(err => err.youtubeAdvertisingConsecutive));
            setBudgetConsecutiveMonthsMirrorDisplayTacticsErr(consecutiveErrors?.find(err => err.mirrorDisplayConsecutive));
    }
         
    }, [updatedBudgetEntries, budgetPlan]);

    if (tactics.isLoading) {
        return <Loader loadingMessage='Loading tactics...' />;
    }

    return (
        <Fragment>
            <Fragment>
                {renderTactics()}
                {addingCustomTactic &&
                    <AddCustomTacticForm
                        onSuccess={() => setAddingCustomTactic(false)}
                        onCancel={() => setAddingCustomTactic(false)}
                    />}
                <div>
                    {renderTotal()}
                    {
                        validationErrors?.map((err,i) =>
                            <div key={i} >
                                <small className='error-msg'>
                                    <i className='fas fa-times-circle'></i>
                                    {` ${err.message}` /*space needed for presentation purposes*/}
                                </small>
                            </div>
                            )
                    }
                    {
                        // Note: commented code by stakeholder request on date 2023-12-20
                        // minAnnualBudgetPrefferedTcticErr ? (
                        // <small className='error-msg'><br></br> <i className='fas fa-times-circle'></i>{` Total annual spend for preferred tactics (SEO & Local Services, Scorpion One Digital Marketing, PulseM, Targeted Direct Mail, Native Advertising, Out of Home, Youtube Advertising, CTV/OTT w Mirrored Display, Local Social Media Content) must reach a minimum of $${formatCurrency(
                        //     budgetPlan.tacticsMinimumAnnualSpend
                        // )}`}</small>
                        // ) : null
                    }
                    {
                        minMonthlyBudgetScorpionErr ? (
                        <small className='error-msg'><br></br> <i className='fas fa-times-circle'></i>{` For Scorpion One Digital Marketing Tactic you have to spend a minimum of $${formatCurrency(
                            budgetPlan.scorpionMinMonthlySpendAmount
                        )} dollars per month.`}</small>
                        ) : null
                    }
                    {
                        minMonthlyBudgetTDmErr ? (
                        <small className='error-msg'><br></br> <i className='fas fa-times-circle'></i>{` For Targeted Direct Mail Tactic you have to spend a minimum of $${formatCurrency(
                            budgetPlan.targatedDmMinMonthlySpendAmount
                        )} dollars per month.`}</small>
                        ) : null
                    }
                    {
                        minAnnualBudgetExeptDiscretionaryMarketingSpendErr ? (
                        <small className='error-msg'><br></br> <i className='fas fa-times-circle'></i>{` Total annual spend  except DiscretionaryMarketingSpendTactic must reach a minimum of $${formatCurrency(
                            budgetPlan.tacticsMinimumAnnualSpend
                        )}`}</small>
                        ) : null
                    }
                    {
                        minMonthlyBudgetNativeAdvertisingErr ? (
                        <small className='error-msg'><br></br> <i className='fas fa-times-circle'></i>{` For Native Advertising Tactic you have to spend a minimum of $${formatCurrency(
                            budgetPlan.nativeAdvertisingMinMonthlySpendAmount
                        )} dollars per month.`}</small>
                        ) : null
                    }
                    {
                        minMonthlyBudgetyoutubeAdvertisingErr ? (
                        <small className='error-msg'><br></br> <i className='fas fa-times-circle'></i>{` For YouTube Advertising Tactic you have to spend a minimum of $${formatCurrency(
                            budgetPlan.youtubeAdvertisingMinMonthlySpendAmount
                        )} dollars per month.`}</small>
                        ) : null
                    }
                    {
                        minMonthlyBudgetMirroredDisplayErr ? (
                        <small className='error-msg'><br></br> <i className='fas fa-times-circle'></i>{` For CTV/OTT w Mirrored Display Tactic you have to spend a minimum of $${formatCurrency(
                            budgetPlan.mirroredDisplayMinMonthlySpendAmount
                        )} dollars per month.`}</small>
                        ) : null
                    }
                    {
                        minMonthlyBudgetMultiviewErr ? (
                        <small className='error-msg'><br></br> <i className='fas fa-times-circle'></i>{` For Multiview Digital B2B Advertising Tactic you have to spend a minimum of $${formatCurrency(
                            budgetPlan.multiviewMinMonthlySpendAmount
                        )} dollars per month.`}</small>
                        ) : null
                    }
                    {
                        budgetConsecutiveMonthsNativeAdvertisingTacticsErr ? (
                        <small className='error-msg'><br></br> <i className='fas fa-times-circle'></i>{` For Native Advertising Tactic, if amount other than zero, must have nonzero value for 3 consecutive months`}</small>
                        ) : null
                    }
                    {
                        budgetConsecutiveMonthsYoutubeAdvertisingTacticsErr ? (
                        <small className='error-msg'><br></br> <i className='fas fa-times-circle'></i>{` For YouTube Advertising Tactic, if amount other than zero, must have nonzero value for 3 consecutive months`}</small>
                        ) : null
                    }
                    {
                        budgetConsecutiveMonthsMirrorDisplayTacticsErr ? (
                        <small className='error-msg'><br></br> <i className='fas fa-times-circle'></i>{` For CTV/OTT w Mirrored Display Tactic, if amount other than zero, must have nonzero value for 3 consecutive months`}</small>
                        ) : null
                    }
                </div>
            </Fragment>
        </Fragment>
    );
};


Tactics.propTypes = {
    templateId: PropTypes.number.isRequired,
    updatedBudgetEntries: PropTypes.array.isRequired,
};

export default Tactics;
