import React, { Component } from 'react';
import { connect } from 'react-redux';

import userSession from 'utils/userSession';
import { confirmFranchiseInfo } from 'actions/franchiseActions';
import InlineNotification from 'components/common/inline-notification/InlineNotification';
import { setHaveUnsavedChangesAction } from 'actions/statusAccions';

export class FranchiseInfoConfirmation extends Component {

    onChecked = (value) => {
        this.props.setHaveUnsavedChanges(true);
        this.props.confirmFranchiseInfo(true, value);
    }

    componentDidMount() {
        const { 
            infoConfirmed,
            correctFranchiseInfo
        } = this.props;

            if(!infoConfirmed) {
                this.props.confirmFranchiseInfo(false, null);
            } else {
                this.props.confirmFranchiseInfo(true, correctFranchiseInfo);
            }
    }

    render() {
        const {
            infoConfirmed,
            correctFranchiseInfo,
            overrideMailingPlan
        } = this.props;

        return (
            <div className='franchise-info-confirmation-container'>
                <small className='confirm-info-title'>Is this information correct?</small>
                {
                    !infoConfirmed &&
                        <small className='confirm-info-title error d-block'>Please confirm your franchise information</small>
                }
                <div className='confirm-info-options'>
                    <div className='confirm-option'>
                        <input 
                            checked={(infoConfirmed && correctFranchiseInfo) ? true : false}
                            disabled={
                                userSession.isDivisionalUser() && 
                                !overrideMailingPlan
                            }
                            type='checkbox'
                            onChange={() => this.onChecked(true)}
                            />
                        <div className='ml-2'>Yes</div>
                    </div>
                    <div className='confirm-option'>
                        <input 
                            checked={(infoConfirmed && !correctFranchiseInfo) ? true : false}
                            disabled={
                                userSession.isDivisionalUser() && 
                                !overrideMailingPlan
                            }
                            type='checkbox'
                            onChange={() => this.onChecked(false)}
                            />
                        <div className='ml-2'>No</div>
                    </div>
                </div>
                {
                    infoConfirmed && !correctFranchiseInfo &&
                        <div className='mt-3'>
                            <InlineNotification
                                type='info'
                                message={<small>Something out of place? Please let us know in the comments box below.</small>}
                            />
                        </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    correctFranchiseInfo: state.mailingPlan?.isCorrectFranchiseInfo,
    infoConfirmed: state.mailingPlan?.isFranchiseInfoConfirmed,
    overrideMailingPlan: state.mailingPlan?.overrideEnabled
});

export default connect(mapStateToProps, {
    confirmFranchiseInfo,
    setHaveUnsavedChanges: setHaveUnsavedChangesAction
})(FranchiseInfoConfirmation);
