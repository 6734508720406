const brandWrapper = brandData => {
    if(
        !brandData
        || Array.isArray(brandData)
        || typeof brandData !== 'object'
    ) return {};

    let brandColors = JSON.parse(brandData?.theme);

    return {
        brandId: brandData?.id,
        symbol: brandData?.symbol,
        brandName: brandData?.name,
        prefix: brandData?.prefix,   
        primaryColor: brandColors?.primaryColor,
        secondaryColor: brandColors?.secondaryColor,
        tertiaryColor: brandColors?.tertiaryColor,
        icon: brandData?.url_small_logo,
        toggle_lock_date_reminder: brandData?.toggle_lock_date_reminder,
        version_no: brandData?.version_no,
    }
}

export default brandWrapper;