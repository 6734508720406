import React, { Component } from 'react';
import PropTypes from 'prop-types';

import IconButton from 'components/common/icon-button/IconButton';
import Input from 'components/common/input/Input';

import formatDate from 'utils/formatDate';

export class CampaignsConfigItem extends Component {
    state = { 
        isEditing: false,
        editFieldName: '',
        editFieldDate: '',
        editFieldLockDate: ''
    }

    static propTypes = {
        campaign: PropTypes.object,
        onEditCampaign: PropTypes.func,
        onRemoveCampaign: PropTypes.func
    }

    onEdit = () => {
        this.setState({ isEditing: true });
    }

    onSaveChanges = (campaign = null) => {
        const {
            editFieldName,
            editFieldDate,
            editFieldLockDate
        } = this.state;

        this.setState({ isEditing: false });
        this.props.onEditCampaign({
            ...campaign,
            campaignName: editFieldName,
            date: editFieldDate,
            lockDate: editFieldLockDate
        });
    }

    onCancel = () => {
        this.setState({ isEditing: false });
    }

    componentDidMount() {
        const { campaign } = this.props;

        this.setState({
            editFieldName: campaign?.campaignName || '',
            editFieldDate: campaign?.date || '',
            editFieldLockDate: campaign?.lockDate || ''
        });
    }

    render() {
        const { 
            isEditing,
            editFieldName,
            editFieldDate,
            editFieldLockDate
        } = this.state;

        const { 
            campaign,
            onRemoveCampaign
        } = this.props;

        return (
            <div className='campaign-config-item mb-3'>
                <div className='border-right flex-grow-1 pr-2'>
                    {
                        !isEditing ?
                            <span className='title'>{campaign?.campaignName}</span>
                        :
                            <Input 
                                label='Campaign Name'
                                placeholder='Campaign name'
                                type='text'
                                value={editFieldName}
                                onChange={(e) => this.setState({ editFieldName: e.target.value })}
                            />
                    }
                </div>
                <div className='date border-right flex-grow-1 pr-2'>
                    {
                        !isEditing ?
                            `Date: ${formatDate.date(campaign?.date)}`
                        :
                            <Input 
                                label='Campaign Date'
                                value={formatDate.toISOFormat(editFieldDate)}
                                type='date'
                                onChange={(e) => this.setState({ editFieldDate: e.target.value })}
                            />
                    }
                </div>
                <div className='lock-date border-right flex-grow-1 pr-2'>
                    {
                        !isEditing ?
                            `Lock Date: ${formatDate.date(campaign?.lockDate)}`
                        :
                            <Input
                                label='Lock Date'
                                value={formatDate.toISOFormat(editFieldLockDate)}
                                type='date'
                                onChange={(e) => this.setState({ editFieldLockDate: e.target.value })}
                            />
                    }
                </div>
                {
                    campaign?.markedForRemoval ?
                        <div className='d-flex align-items-center'>
                            <div className='c-danger mr-2'>Marked for removal</div>
                            <i 
                                className='fas fa-times c-neutral cursor-pointer'
                                onClick={() => onRemoveCampaign(campaign, false)}
                            ></i>
                        </div>
                    :
                        <div className='actions'>
                            <IconButton 
                                icon={isEditing ? 'fas fa-check' : 'fas fa-pencil-alt'}
                                shape='circle'
                                btnType='primary'
                                action={() => {
                                    if(isEditing) {
                                        this.onSaveChanges(campaign);
                                    } else {
                                        this.onEdit();
                                    }
                                }}
                            />
                            <IconButton 
                                icon={isEditing ? 'fas fa-times' : 'fas fa-trash-alt'}
                                shape='circle'
                                btnType='danger'
                                action={() => {
                                    if(isEditing) {
                                        this.onCancel();
                                    } else {
                                        onRemoveCampaign(campaign, true);
                                    }
                                }}
                                disabled={campaign?.isAssigned && !isEditing}
                                tooltipMsg={(campaign?.isAssigned && !isEditing) ? 'This campaign cannot be deleted since its already being used' : null}
                            />
                        </div>
                }
            </div>
        );
    }
}

export default CampaignsConfigItem;
